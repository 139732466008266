.modalContainer {
  z-index: 10;
  position: fixed;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.6);

  .modal {
    position: relative !important;

    background: #FFFFFF;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.45);
    outline: none;

    display: flex;
    flex-direction: column;

    max-width: 672px;
    border-radius: 10px;

    padding: 48px;

    .title {
      font-family: 'Lato', sans-serif;
      font-size: 34px;
      line-height: 41px;
      font-style: normal;

      text-align: center;

      color: #000000;
    }

    .content {
      font-style: normal;
      font-weight: normal;
      line-height: 21px;
      font-size: 14px;

      color: #000000;

      margin: 20px 0 30px;
    }

    &.centered {
      align-items: center;
      justify-content: center;
      width: 540px;
      padding: 60px 100px 50px;
      border-radius: 0;

      button {
        margin-right: 0 !important;
      }

      .title {
        line-height: 28px;
        font-size: 19px;
        font-weight: bold;
      }

      .content {
        text-align: center;
      }
    }
  }
}