.container {
  height: 600px;

  .left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .text {
      width: 620px;
      text-align: center;

      font-family: 'PT Sans', sans-serif;
      font-size: 24px;
      line-height: 1.33;

      b, a {
        font-weight: normal;
        color: #cf3630;
      }

      h5 {
        margin: 0;
        font-size: 32px;
      }

      ul {
        text-align: left;
        font-size: 16px;
        line-height: 1.75;
        list-style: none;
        margin-left: 20px;
        margin-top: 50px;
        width: 630px;
      }
    }
    .count {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;

      font-size: 12px;
      line-height: 2.67;
    }
  }

  &.hasRight {
    display: flex;
    justify-content: center;

    .left {
      justify-content: start;
      padding-top: 155px;

      .text {
        width: 350px;
        text-align: left;
      }
      .count {
        text-align: left;
      }
    }

    .right {
      margin-left: 45px;
      margin-top: 95px;
      max-width: 480px;
    }
  }


  @media (max-width: 1200px) {
    height: 520px;

    .left {
      .text {
        font-size: 16px;
        line-height: normal;

        width: auto !important;
        max-width: 400px;
        padding: 0 15px;

        h5 {
          font-size: 24px;
        }

        ul {
          width: 300px;
        }
      }
    }

    &.hasRight {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .left {
        padding: 0;
        z-index: 2;

        .text {
          text-align: center;

          max-height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .count {
          text-align: center;
        }
      }

      .right {
        margin: 20px 0 0;
        order: -1;
        height: 310px;
        flex-shrink: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        img {
          height: 420px;
        }

        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 100px;
          display: block;
          height: 320px;
          width: calc(100% + 2px);
          background: linear-gradient(rgba(32, 32, 32, 0), rgba(32, 32, 32, 1) 62%);
        }
      }
    }

    &.web {
      .right {
        img {
          height: 280px;
        }

        &:after {
          display: none;
        }
      }
    }
  }
}