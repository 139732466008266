.container {
  display: flex;
  margin-top: 27px;
  span {
    line-height: 28px;
    font-size: 16px;
    color: #919191;
    margin-right: 12px;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  @media (max-width: 1400px) {
    ul li, span {
      line-height: 25px;
      font-size: 14px;
    }
  }
}

.select {
  width: 350px;
  margin-top: 30px;

  > div:first-of-type {
    height: 40px;

    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    border-radius: 1px;
  }

  * {
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    font-size: 13px;

    color: #000000;
  }
}