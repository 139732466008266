.container {
  display: flex;

  p.red {
    color: #E31E24;
  }

  .modal {
    max-width: 672px;

    background: #FFFFFF;
    border-radius: 10px;
  }
}