.container {
  width: 100%;
  position: relative;
  height: 600px;
  max-width: 1140px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .arrow {
    position: absolute;
    z-index: 2;
    height: 82px;
    width: 82px;
    background: url("./Images/arrow.png") center no-repeat;
    top: calc(50% - 41px);
    pointer-events: none;

    &.active {
      background-image: url("./Images/arrow_active.png");
      cursor: pointer;
      pointer-events: initial;
    }
  }
  .arrowLeft {
    @extend .arrow;
    left: 0;
    transform: scaleX(-1);
  }
  .arrowRight {
    @extend .arrow;
    right: 0;
  }

  @media (max-width: 1200px) {
    height: 520px;

    .arrow {
      display: none;
    }

    .hint {
      position: absolute;
      z-index: 2;
      width: 54px;
      height: 37px;
      background: url("./Images/hint.png");
      left: 20px;
      bottom: 0;
    }
  }
}