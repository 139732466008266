.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    max-width: 400px;
  }

  @media (max-width: 1200px) {
    img {
      max-width: 100%;
      width: 100%;
    }
  }
}