.container {
  width: 288px;
  background-color: #fff;
  cursor: pointer;
  margin-top: 40px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  >div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &:not(:nth-child(4n)) {
    margin-right: 40px;
  }
  img {
    max-width: 288px !important;
  }
  .content {
    padding: 30px 20px 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 210px;
    .type {
      position: absolute;
      top: 26px;
      right: 8px;
      width: 36px;
    }
    .title {
      padding-right: 40px;
      font-weight: bold;
      line-height: 25px;
      font-size: 15px;
      color: #000000;
      margin-top: 0;
      margin-bottom: 14px;

      text-overflow: ellipsis;
      overflow: hidden;
    }
    .description {
      line-height: 21px;
      font-size: 13px;
      color: #000000;
      margin-top: 0;
      margin-bottom: 20px;

      text-overflow: ellipsis;
      overflow: hidden;
    }
    .info {
      margin-top: auto;
      span {
        line-height: 23px;
        font-size: 14px;
        color: #474747;
        margin-right: 10px;
      }
      .users {
        &:before {
          background-image: url("../Images/user.svg");
          background-repeat: no-repeat;
          display: inline-block;
          width: 12px;
          height: 15px;
          content: "";
          margin-right: 10px;
          background-position-y: 4px;
        }
      }
    }
  }

  @media (max-width: 1400px) {
    width: 245px;

    img {
      max-width: 245px !important;
    }

    &:not(:nth-child(4n)){
      margin-right: -1px;
    }
    &:not(:nth-child(3n)) {
      margin-right: 30px;
    }

    @media (max-width: 833px) {
      width: 350px;
      margin-right: 0 !important;
      margin-top: 35px;

      img {
        max-width: 350px !important;
      }

      .content { height: unset; }
    }
  }
}
