.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 0 50px;

  .title {
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 64px;

    text-align: center;

    color: #FFFFFF;

    margin-bottom: 50px;
  }

  .form {
    .input {
      width: 338px;
      color: #FFFFFF;
      margin-right: 26px;
      height: 40px;
      background-color: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid #FFFFFF;

      padding-bottom: 14px;

      &::placeholder {
        opacity: 0.6;
      }

      &.invalid {
        border-bottom-color: #E31E24;
      }
    }

    button {
      height: 48px;
    }
  }

  .privacy {
    margin-top: 40px;

    line-height: 20px;
    font-size: 12px;
    text-align: center;

    color: #FFFFFF;

    a {
      color: #E31E24;
      text-decoration: none;
    }
  }

  .success {
    font-size: 28px;
    color: #E31E24;
  }

  @media (max-width: 1400px) {
    .title {
      font-size: 42px;
    }

    .success {
      font-size: 24px;
    }

    @media (max-width: 835px) {
      .form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .input {
          margin: 0 0 30px 0;
          width: 286px;
        }
      }

      .privacy {
        width: 320px;
      }
    }
  }
}