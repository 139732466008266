$max: 12;
$angle: 360/$max;
$size: 120px;
$fill: #E31E24;

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: $size;
    width: $size;
    overflow: visible;
  }

  @function transform($item: 1) {
    $base: rotate(#{-$angle*$item}deg) translate(5px, 5px);
    @return $base;
  }

  .circle {
    transform-origin: $size/2 $size/2;

    fill: $fill;
    animation: opacity 1.2s linear infinite;

    @for $item from 1 through $max {
      &:nth-child(#{$max}n + #{$item}) {
        animation-delay: -#{$item/10}s;
        transform: transform($item) scale(.9);
      }
    }
  }

  @keyframes opacity {
    3% {
      fill-opacity: 1;
    }
    75% {
      fill-opacity: 0;
    }

  }
}