.container {
  background: black;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-family: 'Lato', sans-serif;
  font-size: 64px;
  line-height: 77px;
  color: #FFFFFF;

  img {
    margin-bottom: 35px;
  }
}