.header {
  position: fixed;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 50px;
  top: 0;

  p {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-right: 18px;
    cursor: pointer;
    &.active {
      color: #E31E24;
    }
  }

  .left {
    display: flex;
    a {
      display: flex;
    }
    .logo {
      margin-right: 52px;
    }
    .mobileLogo {
      display: none;
    }
  }
  * {
    color: #fff;
  }
  &.white {
    background-color: #fff;
    animation: fromUp .3s linear 0s;
    p {
      color: #000;
      &.active {
        color: #E31E24;
      }
    }
    .languages {
      li {
        color: black;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
  }
  .languages {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      display: inline;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 14px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      opacity: 0.4;
      &:not(:last-of-type) {
        margin-right: 10px;
      }
      &:hover {
        opacity: 0.7;
      }
      &.active {
        opacity: 1;
      }
    }
  }
}

.menu {
  display: none;
}

.header.isMobile, .header.white.isMobile {
  z-index: 4;
  height: 100vh;
  width: 320px;
  background-color: black;
  display: flex;
  right: -320px;
  flex-direction: column;
  animation: none;
  transition: 0.3s;

  p {
    font-weight: 600;
    line-height: normal;
    font-size: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #E5E5E5;
  }

  .left {
    flex-direction: column;

    .logo {
      display: none;
    }
    .mobileLogo {
      display: block;
      width: 154px;
      margin-top: 88px;
      margin-bottom: 60px;
    }
  }

  .right {
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
      margin-bottom: 20px;
    }

    margin-bottom: 140px;
  }

  &.showOnMobile {
    right: 0;
  }

  .close {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  .languages {
    margin-bottom: 30px;
    li {
      color: #fff;
    }
  }
}

.menu.isMobile {
  cursor: pointer;
  position: absolute;
  z-index: 3;
  display: block;
  right: 12px;
  top: 12px;
}

@keyframes fromUp {
  0% {
    top: -80px;
  }
  100% {
    top: 0;
  }
}
