.container {
  margin: 140px 0 80px;

  display: flex;
  justify-content: center;

  .content {
    h1 {
      margin: 0 0 40px;
    }
  }

  @media (max-width: 1200px) {
    margin: 0 0 55px;

    .content {
      width: 100%;
      h1 {
        margin: 0;
      }
    }
  }
}
