.container {
  margin: 140px 0 80px;

  display: flex;
  justify-content: center;

  .content {
    width: 1000px;

    .columns {
      display: flex;
      justify-content: space-between;

      .left {
        width: 450px;

        img {
          margin: 25px 0 30px;
        }

        td {
          padding-bottom: 10px;
        }

        td:first-of-type, .label {
          line-height: 19px;
          font-size: 13px;

          min-width: 180px;

          color: #000000;
        }

        td:last-of-type, .value {
          font-weight: 600;
          line-height: 19px;
          font-size: 13px;

          color: #000000;
        }

        .label {
          margin: 30px 0 10px;
        }

        button {
          margin-top: 30px;
        }
      }

      .right {
        width: 277px;
        height: 315.55px;

        margin: 30px 40px;

        background-image: url("./microphone.svg");
        background-position: right top;
        background-repeat: no-repeat;
      }
    }
  }

  @media (max-width: 1200px) {
    margin: 0;

    .content {
      width: 100%;

      .columns {
        .left {
          width: 100%;

          table {
            padding: 30px 30px 0;

            tr {
              display: flex;
              flex-direction: column;

              td:first-of-type {
                padding-bottom: 8px;
              }

              td:last-of-type {
                font-size: 14px;
                padding-bottom: 20px;
              }
            }
          }

          .label {
            margin-top: 0;
            margin-left: 30px;
          }

          .value {
            font-size: 14px;
            margin-left: 30px;
          }

          button {
            margin-left: 30px;
            margin-bottom: 45px;
            width: 200px;
          }
        }

        .right {
          display: none;
        }
      }
    }
  }
}