.form {
  display: flex;

  .fields {
    width: 580px;

    padding: 20px 80px;

    background: #F6F6F6;

    .row {
      margin-top: 25px;

      display: flex;

      >div {
        width: 100%;

        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }

      small {
        font-style: normal;
        font-weight: normal;
        line-height: 19px;
        font-size: 13px;

        color: #9F9F9F;
      }

      .location {
        background-image: url("./address.svg");
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: calc(100% - 20px);

        padding-right: 50px;
      }

      &:nth-child(5) {
        >div {
          &:nth-child(3) {
            width: 50%;
            min-width: 55px;
          }
        }
      }
    }
  }

  .cover {
    margin-left: 50px;
    width: 400px;

    .title {
      margin: 45px 0 20px;

      font-family: 'Futura PT', sans-serif;
      line-height: 23px;
      font-size: 16px;

      color: #000000;
    }

    input[type="file"] {
      display: none;
    }

    button {
      margin: 30px 0 20px;
    }

    small {
      display: block;

      font-style: normal;
      font-weight: normal;
      line-height: 21px;
      font-size: 14px;

      color: #9F9F9F;
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column-reverse;

    .fields {
      width: 100%;
      padding: 0 10px;
      background-color: transparent;

      .row {
        margin: 0;

        &:nth-child(1), &:nth-child(2) {
          display: flex;
          flex-direction: column;
        }

        label {
          margin-top: 21px;
          margin-bottom: 8px;
        }

        &:nth-child(4) {
          >div {
            width: 100% !important;
          }
        }

        &:nth-child(5) {
          >div {
            &:nth-child(1) {
              width: calc(50% - 5px);
              flex-shrink: 0;
            }

            &:nth-child(2){
              min-width: 80px;

              label {
                white-space: nowrap;
              }
            }
          }
        }

        &:nth-child(9), &:nth-child(11) {
          margin-top: 21px;
        }

        &:nth-child(10) {
          margin-top: 35px;
        }

        small {
          white-space: unset !important;
        }
      }
    }

    .cover {
      margin: 0;
      width: 100%;
      position: relative;

      .title {
        display: none;
      }

      button {
        margin: 0;
        background-color: transparent;
        color: transparent;
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left: 0;
      }

      &:after {
        content: url("./photo.svg");
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 29.5px);
        pointer-events: none;
      }

      small {
        display: none;
      }
    }
  }
}
