@keyframes blink {
  50% {
    opacity: 0.0;
  }
}

.container {
  height: 100%;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  width: 100vw;
  overflow-x: hidden;
}

.arrow_down {
  width: 0;
  height: 0;
  border-left: 19px solid transparent;
  border-right: 19px solid transparent;
  border-top: 19px solid #E31E24;
  position: fixed;
  bottom: 30px;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  animation: blink 1s linear 0s infinite;
}

.video {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  video {
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: calc(100% + 20px);
    z-index: -1;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: blur(5px);
    @-moz-document url-prefix() {
      filter: none;
    }
  }
}

.block {
  position: relative;
  scroll-snap-align: start;
  background: transparent;
  height: 100vh;
  display: flex;
  .inner {
    max-width: 1400px;
    width: 1400px;
    display: flex;
  }
  * {
    color: #fff;
  }
  p {
    line-height: 28px;
    font-size: 16px;
    margin: 0;
    margin-bottom: 16px;
  }
  h2 {
    line-height: normal;
    font-size: 64px;
    font-weight: 300;
    margin: 0;
    margin-bottom: 42px;
  }

  button:last-of-type {
    margin-right: 0;
  }
}

.block_1 {
  @extend .block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .logo {
    margin-bottom: 80px;
  }
  .slogan {
    line-height: 32px;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-weight: 300;
    strong {
      font-weight: 300;
      color: #E31E24;
    }
  }
}

.block_2 {
  @extend .block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .inner {
    align-items: center;
    justify-content: center;
  }
  .content {
    max-width: 540px;
    margin-right: 150px;
    .buttons {
      display: flex;
      margin-top: 70px;
    }
  }
}

.block_3 {
  @extend .block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-bottom: 70px;
    text-align: center;
  }
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .button {
    margin-top: 95px;
  }
  .features {
    display: flex;
    margin-bottom: 50px;
    .feature {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 270px;
      &:not(:last-of-type) {
        margin-right: 40px;
      }
      p {
        line-height: 25px;
        font-size: 14px;
        text-align: center;
        color: #FFFFFF;
        margin: 0;
      }
      h3 {
        font-weight: 300;
        line-height: normal;
        font-size: 34px;
        text-align: center;
        color: #FFFFFF;
        margin: 0;
        margin-bottom: 16px;
      }
    }
  }
  .items {
    display: flex;
    .item {
      display: flex;
      flex-direction: column;
      max-width: 140px;
      align-items: center;
      .image {
        min-width: 140px;
        min-height: 140px;
        width: 140px;
        height: 140px;
        background-color: #E31E24;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }
      &:not(:last-child) {
        margin-right: 90px;
      }
      span {
        max-width: 190px;
        line-height: 21px;
        font-size: 13px;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
}

.howToUseBlock {
  @extend .block;
  height: 880px;
  background-color: #202020;
  padding: 70px 0 50px;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;

  @media (max-width: 1200px) {
    height: 660px;
    padding: 15px 0;

    h2 {
      margin-bottom: 10px;
    }

    .buttons {
      button {
        width: 90px;
      }
    }
  }
}

.block_mobile {
  @extend .block;
  background: #E31E24;
  justify-content: center;
  align-items: center;
  background-image: url("./Images/mobile.svg");
  background-repeat: no-repeat;
  background-position-x: 80%;
  .inner {
    flex-direction: column;
    p {
      max-width: 530px;
    }
    .buttons {
      margin-top: 60px;
      >*:nth-child(1),
      >*:nth-child(2) {
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .container {
    scroll-snap-type: none;
  }

  .arrow_down {
    display: none;
  }

  @media (max-width: 835px) {
    .block_1 {
      .logo {
        width: 270px;
        margin-bottom: 35px;
      }

      .slogan {
        font-weight: 300;
        line-height: 21px;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.04em;
      }
    }

    .block {
      width: 100vw;
      overflow: hidden;
      h2 {
        font-weight: 300;
        line-height: normal;
        font-size: 36px;
        text-align: center;
      }

      p {
        font-style: normal;
        font-weight: normal;
        line-height: 30px;
        font-size: 14px;
        text-align: center;
      }
    }

    .block_2 {
      height: auto;
      .inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        .content {
          margin: 0;
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 80vw;

          p {
            text-align: center;
          }

          .buttons {
            margin-top: 50px;
            display: flex;
            flex-direction: column;

            > * {
              width: 258px;
              height: 50px;
              margin-bottom: 22px;
              margin-right: 0;
            }
          }
        }

        img {
          max-width: 80vw;
        }
      }
    }

    .block_3 {
      .features {
        display: none;
      }
    }
  }

  @media (min-width: 834px) {
    .block {
      h2 {
        font-weight: 300;
        line-height: normal;
        font-size: 42px;
        text-align: center;
      }

      p {
        line-height: 25px;
        font-size: 14px;
      }
    }

    .block_2 {
      min-height: 1117px;
      .inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        .content {
          margin: 0;
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          h2 {
            white-space: nowrap;
          }

          p {
            text-align: center;
          }
        }
      }
    }

    .block_3 {
      min-height: 900px;
      .items {
        display: flex;
        .item {
          &:not(:last-of-type) {
            margin-right: 25px;
          }

          span {
            white-space: normal;
            br {
              display: none;
            }
          }
        }
      }

      .features {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 0;

        .feature {
          max-width: unset;
          width: 33%;
          margin-bottom: 50px;

          p {
            br {
              display: none;
            }
          }
        }
      }

      &:nth-of-type(5) {
        button {
          margin-top: 60px;
        }
      }
    }

    .block_mobile {
      min-height: unset;
      height: 685px;
      background-size: 450px;
      background-position-x: 90%;

      padding-left: 60px;

      justify-content: flex-start;

      .inner {
        width: 300px;

        h2 {
          text-align: left;
        }

        img {
          margin-bottom: 20px;
        }

        .buttons {
          margin-top: 40px;
        }
      }
    }
  }

  @media (max-width: 833px) {
    .block_3 {
      min-height: unset;
      height: 665px;

      .content {
        .items {
          width: 100vw;
          overflow: scroll;
          padding-left: calc(50vw - 100px);

          &:after {
            content: "";
            display: block;
            width: calc(50vw - 160px);
            flex-shrink: 0;
          }

          .item {
            transform: translate3d(0px, 0px, 0px) !important;
            opacity: 1 !important;
            max-width: 200px;
            margin-right: 56px;
            .image {
              opacity: 1 !important;
              width: 200px;
              height: 200px;
            }

            position: relative;

            &:first-of-type:after {
              position: absolute;
              content: url("./hint.svg");
              top: 80px;
              left: -75px;
            }

            span {
              white-space: normal;

              font-style: normal;
              font-weight: normal;
              line-height: 30px;
              font-size: 14px;
            }
          }
        }

        button {
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }
    }

    .block_mobile {
      display: none;
    }
  }
}