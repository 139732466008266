.container {
  scroll-snap-align: start;
  background-color: #F4F4F4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 96px 0;
  .inner {
    width: 1272px;
    display: flex;
    flex-direction: column;
  }

  .loader {
    height: 288px;

    svg {
      margin: 40px 0 0;
      width: 160px;
      height: 160px;
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;
  }
  h2 {
    font-weight: 600;
    line-height: normal;
    font-size: 42px;
    color: #000000;
    margin: 0;
    font-family: 'PT Mono', monospace;
  }
  .button {
    margin-top: 94px;
    align-self: center;
  }

  @media (max-width: 1400px) {
    padding: 65px 20px;

    .inner {
      .items {
        width: 795px;
      }
    }

    .button {
      margin-top: 60px;
    }

    @media (max-width: 833px) {
      padding: 50px 12px;

      .button {
        margin-top: 35px;
      }

      .inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          font-weight: 300;
          line-height: normal;
          font-size: 28px;
          text-align: center;
        }

        .items {
          flex-direction: column;
          align-items: center;

          .item {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}
