.container {
  display: none;

  margin: 0 0 30px;

  @media (max-width: 1200px) {
    display: block;

    font-family: 'PT Mono', monospace;
    font-weight: 300;
    line-height: normal;
    font-size: 28px;

    color: #000000;
  }
}