.container {
  .modal {
    width: 672px;
    border-radius: 10px;

    padding: 48px 48px 28px;

    >div {
      width: 100%;
    }
  }
}