.footer {
  background: #000;
  padding: 60px 50px;
  scroll-snap-align: start;
  a {
    text-decoration: none;
    line-height: 23px;
    font-size: 13px;
    color: #9E999C;

  }
  .content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
  }
  .left {
    p {
      line-height: 23px;
      font-size: 13px;
      color: #E5E5E5;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    ul {
      list-style-type: none;
      padding: 0;
      margin-top: 0;
      margin-bottom: 20px;
    }
    ul > li {
      cursor: pointer;

      display: inline;
      font-weight: 600;
      line-height: normal;
      font-size: 13px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #E5E5E5;
      &:not(:last-of-type) {
        margin-right: 14px;
      }
    }

    button {
      margin-right: 0;
    }
  }

  .app {
    display: none;
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-bottom: 35px;

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 108px;
        }

        p {
          margin: 20px 0 30px;
        }
      }

      .right {
        ul {
          display: none;
        }

        button {
          height: 50px;
          width: 200px;
          padding: 0;
          margin-right: 0;
        }
      }

      .app {
        margin-top: 60px;

        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          line-height: 23px;
          font-size: 13px;
          text-align: center;
          color: #FFFFFF;

          margin-bottom: 20px;
        }

        img {
          cursor: pointer;
        }
      }
    }

    a {
      text-align: center;
    }

    .app {
      display: block;
    }
  }
}
