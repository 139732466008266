.container {
  display: flex;
  flex-direction: column;

  position: relative;

  label {
    margin-bottom: 5px;

    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    font-size: 14px;

    color: #000000;
  }

  input {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 1px;
    height: 40px;

    padding-left: 7px;

    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    font-size: 13px;

    color: #000000;

    &::placeholder {
      color: #9F9F9F;
    }

    &.invalid {
      border-color: #E31E24;

      outline: none;
    }

    &:disabled {
      background: #F6F6F6;
    }
  }

  .error {
    position: absolute;
    bottom: -36px;
    color: #E31E24;
  }
}