.container {
  line-height: 28px;
  font-size: 16px;
  cursor: pointer;
  color: #919191;
  display: inline;
  &:not(:last-child):after {
    content: "|";
    margin-left: 12px;
    margin-right: 12px;
    color: #000000 !important;
  }
  &.active {
    color: #000;
  }
  &:hover {
    color: #E31E24;
  }
}