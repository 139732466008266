.container {
  margin: 140px 0 80px;

  display: flex;
  justify-content: center;

  .content {
    max-width: 1080px;
    iframe {
      margin-top: 10px;
    }

    .columns {
      margin-top: 60px;

      display: flex;

      .left {
        width: 565px;

        >div {
          margin: 10px 0;

          font-style: normal;
          font-weight: normal;
          line-height: 21px;
          font-size: 14px;

          color: #000000;
        }

        .label {
          font-style: normal;
          font-weight: 600;
          line-height: 21px;
          font-size: 14px;

          color: #000000;
        }

        .big {
          line-height: 64px;
          font-size: 64px;

          margin-bottom: 20px;

          display: flex;
          align-items: center;

          .arrows {
            margin-left: 12px;

            display: inline-flex;
            flex-direction: column;

            img:first-of-type {
              margin-bottom: 12px;
            }
          }
        }

        .separator {
          margin: 30px 0;
          border: 1px solid #E5E5E5;
        }

        .warning {
          margin-top: -5px;

          line-height: 18px;
          font-size: 12px;

          color: #E31E24;
        }

        .uploaded {
          display: flex;

          .loader {
            margin-left: 10px;
            svg {
              width: 20px;
              height: 20px;
            }

            circle {
              fill: black;
            }
          }
        }

        .load {
          margin-top: 30px;

          small {
            display: block;
            margin-top: 16px;

            line-height: 19px;
            font-size: 13px;

            color: #919191;
          }
        }

        .bottomWarning {
          margin-top: 30px;

          line-height: 19px;
          font-size: 13px;
          color: #919191;
        }

        .bottom {
          margin-top: 30px;

          display: flex;
          flex-direction: column;

          .row {
            margin-bottom: 20px;
            display: flex;
          }
        }
      }

      .right {
        margin-left: 45px;
        width: 440px;

        display: inline-block;

        >span {
          display: inline-block;
          position: relative;
          cursor: pointer;

          .playSvg {
            position: absolute;
            top: calc(50% - 124px);
          }

          .previewLoader {
            position: absolute;
            top: calc(50% - 60px);
            left: calc(50% - 60px);
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    margin: 0 0 30px;

    .content {
      width: 100%;
      max-width: unset !important;
      .columns {
        margin: 30px;
        flex-direction: column;
        .left {
          width: 100%;

          >div {
            margin: 1px 0;

            line-height: 18px;
            font-size: 12px;
          }

          .label {
            margin-bottom: 5px;
          }

          .big {
            font-size: 48px;
          }

          .lengthAndPrice {
            display: flex;
            align-items: center;

            .label {
              display: none;
            }

            .big:nth-child(3) {
              margin-left: 30px;
              font-size: 18px;
            }
          }

          .load {
            small {
              line-height: 19px;
              font-size: 11px;
            }
          }

          .bottom {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }

          .separator {
            border: none;
          }
        }

        .right {
          margin: -30px 0 30px -30px;
          width: 100vw;
          order: -1;

          >span {
            width: 100%;
            .playSvg {
              left: calc(50% - 210px);
            }

            .videoPlaceholder {
              width: 100%;
            }
          }
        }
      }
    }
  }
}