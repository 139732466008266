@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700|PT+Mono&subset=cyrillic);
@font-face {
  font-family: 'Futura PT';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/FuturaPT-Medium.a61e39ce.eot);
  /* IE9 Compat Modes */
  src: local("Futura PT"), local("FuturaPT"), url(/static/media/FuturaPT-Medium.a61e39ce.eot?#iefix) format("embedded-opentype"), url(/static/media/FuturaPT-Medium.995c64e1.woff) format("woff"), url(/static/media/FuturaPT-Medium.540c81bc.ttf) format("truetype");
  /* Safari, Android, iOS */ }

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html, body, #root {
  height: 100%; }

* {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%; }

.react-datepicker {
  font-size: 14px !important;
  width: 313px; }

label[required]:after {
  content: " *";
  color: #E31E24; }

body .video-react .video-react-big-play-button {
  top: calc(50% - 22.5px);
  left: calc(50% - 45px); }

@-webkit-keyframes Landing_blink__2bJOp {
  50% {
    opacity: 0.0; } }

@keyframes Landing_blink__2bJOp {
  50% {
    opacity: 0.0; } }

.Landing_container__3qol_ {
  height: 100%;
  overflow-y: scroll;
  -webkit-scroll-snap-type: y mandatory;
      -ms-scroll-snap-type: y mandatory;
          scroll-snap-type: y mandatory;
  width: 100vw;
  overflow-x: hidden; }

.Landing_arrow_down__3bgP- {
  width: 0;
  height: 0;
  border-left: 19px solid transparent;
  border-right: 19px solid transparent;
  border-top: 19px solid #E31E24;
  position: fixed;
  bottom: 30px;
  margin: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -1;
  -webkit-animation: Landing_blink__2bJOp 1s linear 0s infinite;
          animation: Landing_blink__2bJOp 1s linear 0s infinite; }

.Landing_video__3w688 {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; }
  .Landing_video__3w688 video {
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: calc(100% + 20px);
    z-index: -1;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-filter: blur(5px);
            filter: blur(5px); }

@-moz-document url-prefix() {
  .Landing_video__3w688 video {
    filter: none; } }

.Landing_block__2ny4u, .Landing_block_1__nwOgM, .Landing_block_2__If-3b, .Landing_block_3__2Nuii, .Landing_howToUseBlock__3kD7Z, .Landing_block_mobile__1y9Jv {
  position: relative;
  scroll-snap-align: start;
  background: transparent;
  height: 100vh;
  display: flex; }
  .Landing_block__2ny4u .Landing_inner__1SjIE, .Landing_block_1__nwOgM .Landing_inner__1SjIE, .Landing_block_2__If-3b .Landing_inner__1SjIE, .Landing_block_3__2Nuii .Landing_inner__1SjIE, .Landing_howToUseBlock__3kD7Z .Landing_inner__1SjIE, .Landing_block_mobile__1y9Jv .Landing_inner__1SjIE {
    max-width: 1400px;
    width: 1400px;
    display: flex; }
  .Landing_block__2ny4u *, .Landing_block_1__nwOgM *, .Landing_block_2__If-3b *, .Landing_block_3__2Nuii *, .Landing_howToUseBlock__3kD7Z *, .Landing_block_mobile__1y9Jv * {
    color: #fff; }
  .Landing_block__2ny4u p, .Landing_block_1__nwOgM p, .Landing_block_2__If-3b p, .Landing_block_3__2Nuii p, .Landing_howToUseBlock__3kD7Z p, .Landing_block_mobile__1y9Jv p {
    line-height: 28px;
    font-size: 16px;
    margin: 0;
    margin-bottom: 16px; }
  .Landing_block__2ny4u h2, .Landing_block_1__nwOgM h2, .Landing_block_2__If-3b h2, .Landing_block_3__2Nuii h2, .Landing_howToUseBlock__3kD7Z h2, .Landing_block_mobile__1y9Jv h2 {
    line-height: normal;
    font-size: 64px;
    font-weight: 300;
    margin: 0;
    margin-bottom: 42px; }
  .Landing_block__2ny4u button:last-of-type, .Landing_block_1__nwOgM button:last-of-type, .Landing_block_2__If-3b button:last-of-type, .Landing_block_3__2Nuii button:last-of-type, .Landing_howToUseBlock__3kD7Z button:last-of-type, .Landing_block_mobile__1y9Jv button:last-of-type {
    margin-right: 0; }

.Landing_block_1__nwOgM {
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .Landing_block_1__nwOgM .Landing_logo__EkX3V {
    margin-bottom: 80px; }
  .Landing_block_1__nwOgM .Landing_slogan__SCNWY {
    line-height: 32px;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-weight: 300; }
    .Landing_block_1__nwOgM .Landing_slogan__SCNWY strong {
      font-weight: 300;
      color: #E31E24; }

.Landing_block_2__If-3b {
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .Landing_block_2__If-3b .Landing_inner__1SjIE {
    align-items: center;
    justify-content: center; }
  .Landing_block_2__If-3b .Landing_content__2lQtj {
    max-width: 540px;
    margin-right: 150px; }
    .Landing_block_2__If-3b .Landing_content__2lQtj .Landing_buttons__2s8VA {
      display: flex;
      margin-top: 70px; }

.Landing_block_3__2Nuii {
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .Landing_block_3__2Nuii h2 {
    margin-bottom: 70px;
    text-align: center; }
  .Landing_block_3__2Nuii .Landing_content__2lQtj {
    display: flex;
    align-items: center;
    flex-direction: column; }
  .Landing_block_3__2Nuii .Landing_button__2I2LM {
    margin-top: 95px; }
  .Landing_block_3__2Nuii .Landing_features__1mtGG {
    display: flex;
    margin-bottom: 50px; }
    .Landing_block_3__2Nuii .Landing_features__1mtGG .Landing_feature__3RK5B {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 270px; }
      .Landing_block_3__2Nuii .Landing_features__1mtGG .Landing_feature__3RK5B:not(:last-of-type) {
        margin-right: 40px; }
      .Landing_block_3__2Nuii .Landing_features__1mtGG .Landing_feature__3RK5B p {
        line-height: 25px;
        font-size: 14px;
        text-align: center;
        color: #FFFFFF;
        margin: 0; }
      .Landing_block_3__2Nuii .Landing_features__1mtGG .Landing_feature__3RK5B h3 {
        font-weight: 300;
        line-height: normal;
        font-size: 34px;
        text-align: center;
        color: #FFFFFF;
        margin: 0;
        margin-bottom: 16px; }
  .Landing_block_3__2Nuii .Landing_items__2G9VT {
    display: flex; }
    .Landing_block_3__2Nuii .Landing_items__2G9VT .Landing_item__12kvJ {
      display: flex;
      flex-direction: column;
      max-width: 140px;
      align-items: center; }
      .Landing_block_3__2Nuii .Landing_items__2G9VT .Landing_item__12kvJ .Landing_image__3x4qi {
        min-width: 140px;
        min-height: 140px;
        width: 140px;
        height: 140px;
        background-color: #E31E24;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px; }
      .Landing_block_3__2Nuii .Landing_items__2G9VT .Landing_item__12kvJ:not(:last-child) {
        margin-right: 90px; }
      .Landing_block_3__2Nuii .Landing_items__2G9VT .Landing_item__12kvJ span {
        max-width: 190px;
        line-height: 21px;
        font-size: 13px;
        white-space: nowrap;
        text-align: center; }

.Landing_howToUseBlock__3kD7Z {
  height: 880px;
  background-color: #202020;
  padding: 70px 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden; }
  @media (max-width: 1200px) {
    .Landing_howToUseBlock__3kD7Z {
      height: 660px;
      padding: 15px 0; }
      .Landing_howToUseBlock__3kD7Z h2 {
        margin-bottom: 10px; }
      .Landing_howToUseBlock__3kD7Z .Landing_buttons__2s8VA button {
        width: 90px; } }

.Landing_block_mobile__1y9Jv {
  background: #E31E24;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/mobile.252d5036.svg);
  background-repeat: no-repeat;
  background-position-x: 80%; }
  .Landing_block_mobile__1y9Jv .Landing_inner__1SjIE {
    flex-direction: column; }
    .Landing_block_mobile__1y9Jv .Landing_inner__1SjIE p {
      max-width: 530px; }
    .Landing_block_mobile__1y9Jv .Landing_inner__1SjIE .Landing_buttons__2s8VA {
      margin-top: 60px; }
      .Landing_block_mobile__1y9Jv .Landing_inner__1SjIE .Landing_buttons__2s8VA > *:nth-child(1),
      .Landing_block_mobile__1y9Jv .Landing_inner__1SjIE .Landing_buttons__2s8VA > *:nth-child(2) {
        margin-right: 10px; }

@media (max-width: 1400px) {
  .Landing_container__3qol_ {
    -webkit-scroll-snap-type: none;
        -ms-scroll-snap-type: none;
            scroll-snap-type: none; }
  .Landing_arrow_down__3bgP- {
    display: none; } }

@media (max-width: 1400px) and (max-width: 835px) {
  .Landing_block_1__nwOgM .Landing_logo__EkX3V {
    width: 270px;
    margin-bottom: 35px; }
  .Landing_block_1__nwOgM .Landing_slogan__SCNWY {
    font-weight: 300;
    line-height: 21px;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.04em; }
  .Landing_block__2ny4u, .Landing_block_1__nwOgM, .Landing_block_2__If-3b, .Landing_block_3__2Nuii, .Landing_howToUseBlock__3kD7Z, .Landing_block_mobile__1y9Jv {
    width: 100vw;
    overflow: hidden; }
    .Landing_block__2ny4u h2, .Landing_block_1__nwOgM h2, .Landing_block_2__If-3b h2, .Landing_block_3__2Nuii h2, .Landing_howToUseBlock__3kD7Z h2, .Landing_block_mobile__1y9Jv h2 {
      font-weight: 300;
      line-height: normal;
      font-size: 36px;
      text-align: center; }
    .Landing_block__2ny4u p, .Landing_block_1__nwOgM p, .Landing_block_2__If-3b p, .Landing_block_3__2Nuii p, .Landing_howToUseBlock__3kD7Z p, .Landing_block_mobile__1y9Jv p {
      font-style: normal;
      font-weight: normal;
      line-height: 30px;
      font-size: 14px;
      text-align: center; }
  .Landing_block_2__If-3b {
    height: auto; }
    .Landing_block_2__If-3b .Landing_inner__1SjIE {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .Landing_block_2__If-3b .Landing_inner__1SjIE .Landing_content__2lQtj {
        margin: 0;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80vw; }
        .Landing_block_2__If-3b .Landing_inner__1SjIE .Landing_content__2lQtj p {
          text-align: center; }
        .Landing_block_2__If-3b .Landing_inner__1SjIE .Landing_content__2lQtj .Landing_buttons__2s8VA {
          margin-top: 50px;
          display: flex;
          flex-direction: column; }
          .Landing_block_2__If-3b .Landing_inner__1SjIE .Landing_content__2lQtj .Landing_buttons__2s8VA > * {
            width: 258px;
            height: 50px;
            margin-bottom: 22px;
            margin-right: 0; }
      .Landing_block_2__If-3b .Landing_inner__1SjIE img {
        max-width: 80vw; }
  .Landing_block_3__2Nuii .Landing_features__1mtGG {
    display: none; } }

@media (max-width: 1400px) and (min-width: 834px) {
  .Landing_block__2ny4u h2, .Landing_block_1__nwOgM h2, .Landing_block_2__If-3b h2, .Landing_block_3__2Nuii h2, .Landing_howToUseBlock__3kD7Z h2, .Landing_block_mobile__1y9Jv h2 {
    font-weight: 300;
    line-height: normal;
    font-size: 42px;
    text-align: center; }
  .Landing_block__2ny4u p, .Landing_block_1__nwOgM p, .Landing_block_2__If-3b p, .Landing_block_3__2Nuii p, .Landing_howToUseBlock__3kD7Z p, .Landing_block_mobile__1y9Jv p {
    line-height: 25px;
    font-size: 14px; }
  .Landing_block_2__If-3b {
    min-height: 1117px; }
    .Landing_block_2__If-3b .Landing_inner__1SjIE {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .Landing_block_2__If-3b .Landing_inner__1SjIE .Landing_content__2lQtj {
        margin: 0;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center; }
        .Landing_block_2__If-3b .Landing_inner__1SjIE .Landing_content__2lQtj h2 {
          white-space: nowrap; }
        .Landing_block_2__If-3b .Landing_inner__1SjIE .Landing_content__2lQtj p {
          text-align: center; }
  .Landing_block_3__2Nuii {
    min-height: 900px; }
    .Landing_block_3__2Nuii .Landing_items__2G9VT {
      display: flex; }
      .Landing_block_3__2Nuii .Landing_items__2G9VT .Landing_item__12kvJ:not(:last-of-type) {
        margin-right: 25px; }
      .Landing_block_3__2Nuii .Landing_items__2G9VT .Landing_item__12kvJ span {
        white-space: normal; }
        .Landing_block_3__2Nuii .Landing_items__2G9VT .Landing_item__12kvJ span br {
          display: none; }
    .Landing_block_3__2Nuii .Landing_features__1mtGG {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 0; }
      .Landing_block_3__2Nuii .Landing_features__1mtGG .Landing_feature__3RK5B {
        max-width: unset;
        width: 33%;
        margin-bottom: 50px; }
        .Landing_block_3__2Nuii .Landing_features__1mtGG .Landing_feature__3RK5B p br {
          display: none; }
    .Landing_block_3__2Nuii:nth-of-type(5) button {
      margin-top: 60px; }
  .Landing_block_mobile__1y9Jv {
    min-height: unset;
    height: 685px;
    background-size: 450px;
    background-position-x: 90%;
    padding-left: 60px;
    justify-content: flex-start; }
    .Landing_block_mobile__1y9Jv .Landing_inner__1SjIE {
      width: 300px; }
      .Landing_block_mobile__1y9Jv .Landing_inner__1SjIE h2 {
        text-align: left; }
      .Landing_block_mobile__1y9Jv .Landing_inner__1SjIE img {
        margin-bottom: 20px; }
      .Landing_block_mobile__1y9Jv .Landing_inner__1SjIE .Landing_buttons__2s8VA {
        margin-top: 40px; } }

@media (max-width: 1400px) and (max-width: 833px) {
  .Landing_block_3__2Nuii {
    min-height: unset;
    height: 665px; }
    .Landing_block_3__2Nuii .Landing_content__2lQtj .Landing_items__2G9VT {
      width: 100vw;
      overflow: scroll;
      padding-left: calc(50vw - 100px); }
      .Landing_block_3__2Nuii .Landing_content__2lQtj .Landing_items__2G9VT:after {
        content: "";
        display: block;
        width: calc(50vw - 160px);
        flex-shrink: 0; }
      .Landing_block_3__2Nuii .Landing_content__2lQtj .Landing_items__2G9VT .Landing_item__12kvJ {
        -webkit-transform: translate3d(0px, 0px, 0px) !important;
                transform: translate3d(0px, 0px, 0px) !important;
        opacity: 1 !important;
        max-width: 200px;
        margin-right: 56px;
        position: relative; }
        .Landing_block_3__2Nuii .Landing_content__2lQtj .Landing_items__2G9VT .Landing_item__12kvJ .Landing_image__3x4qi {
          opacity: 1 !important;
          width: 200px;
          height: 200px; }
        .Landing_block_3__2Nuii .Landing_content__2lQtj .Landing_items__2G9VT .Landing_item__12kvJ:first-of-type:after {
          position: absolute;
          content: url(/static/media/hint.5a7acdac.svg);
          top: 80px;
          left: -75px; }
        .Landing_block_3__2Nuii .Landing_content__2lQtj .Landing_items__2G9VT .Landing_item__12kvJ span {
          white-space: normal;
          font-style: normal;
          font-weight: normal;
          line-height: 30px;
          font-size: 14px; }
    .Landing_block_3__2Nuii .Landing_content__2lQtj button {
      margin-top: 30px;
      margin-bottom: 30px; }
  .Landing_block_mobile__1y9Jv {
    display: none; } }

.Button_button__2INXd {
  background-color: transparent;
  border-radius: 90px;
  border: 1px solid #FFFFFF;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  height: 46px;
  padding: 0 24px;
  cursor: pointer;
  font-weight: bold;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  margin-right: 18px; }
  .Button_button__2INXd.Button_primary__K3ZYZ {
    background-color: #E31E24;
    border: none; }
  .Button_button__2INXd.Button_cancel__yB0UM {
    background: rgba(255, 255, 255, 0.3);
    color: black; }
    .Button_button__2INXd.Button_cancel__yB0UM:not(:hover) {
      border: 2px solid #474747; }
  .Button_button__2INXd.Button_small__3qmnT {
    height: 38px; }
  .Button_button__2INXd:hover, .Button_button__2INXd.Button_disabled__1ZQn7 {
    color: #E31E24;
    background-color: #ECECEC; }
  .Button_button__2INXd.Button_disabled__1ZQn7 {
    cursor: default; }
  .Button_button__2INXd:focus {
    outline: none; }

.Footer_footer__29Lhh {
  background: #000;
  padding: 60px 50px;
  scroll-snap-align: start; }
  .Footer_footer__29Lhh a {
    text-decoration: none;
    line-height: 23px;
    font-size: 13px;
    color: #9E999C; }
  .Footer_footer__29Lhh .Footer_content__3PheW {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px; }
  .Footer_footer__29Lhh .Footer_left__31XDK p {
    line-height: 23px;
    font-size: 13px;
    color: #E5E5E5; }
  .Footer_footer__29Lhh .Footer_right__1My-N {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .Footer_footer__29Lhh .Footer_right__1My-N ul {
      list-style-type: none;
      padding: 0;
      margin-top: 0;
      margin-bottom: 20px; }
    .Footer_footer__29Lhh .Footer_right__1My-N ul > li {
      cursor: pointer;
      display: inline;
      font-weight: 600;
      line-height: normal;
      font-size: 13px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #E5E5E5; }
      .Footer_footer__29Lhh .Footer_right__1My-N ul > li:not(:last-of-type) {
        margin-right: 14px; }
    .Footer_footer__29Lhh .Footer_right__1My-N button {
      margin-right: 0; }
  .Footer_footer__29Lhh .Footer_app__iRzwH {
    display: none; }
  @media (max-width: 1200px) {
    .Footer_footer__29Lhh {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .Footer_footer__29Lhh .Footer_content__3PheW {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 35px; }
        .Footer_footer__29Lhh .Footer_content__3PheW .Footer_left__31XDK {
          display: flex;
          flex-direction: column;
          align-items: center; }
          .Footer_footer__29Lhh .Footer_content__3PheW .Footer_left__31XDK img {
            width: 108px; }
          .Footer_footer__29Lhh .Footer_content__3PheW .Footer_left__31XDK p {
            margin: 20px 0 30px; }
        .Footer_footer__29Lhh .Footer_content__3PheW .Footer_right__1My-N ul {
          display: none; }
        .Footer_footer__29Lhh .Footer_content__3PheW .Footer_right__1My-N button {
          height: 50px;
          width: 200px;
          padding: 0;
          margin-right: 0; }
        .Footer_footer__29Lhh .Footer_content__3PheW .Footer_app__iRzwH {
          margin-top: 60px;
          display: flex;
          flex-direction: column;
          align-items: center; }
          .Footer_footer__29Lhh .Footer_content__3PheW .Footer_app__iRzwH span {
            line-height: 23px;
            font-size: 13px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 20px; }
          .Footer_footer__29Lhh .Footer_content__3PheW .Footer_app__iRzwH img {
            cursor: pointer; }
      .Footer_footer__29Lhh a {
        text-align: center; }
      .Footer_footer__29Lhh .Footer_app__iRzwH {
        display: block; } }

.SubscribeEmail_container__39Bnz {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 50px; }
  .SubscribeEmail_container__39Bnz .SubscribeEmail_title__214yl {
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 64px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 50px; }
  .SubscribeEmail_container__39Bnz .SubscribeEmail_form__2MhKG .SubscribeEmail_input__2WCjX {
    width: 338px;
    color: #FFFFFF;
    margin-right: 26px;
    height: 40px;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid #FFFFFF;
    padding-bottom: 14px; }
    .SubscribeEmail_container__39Bnz .SubscribeEmail_form__2MhKG .SubscribeEmail_input__2WCjX::-webkit-input-placeholder {
      opacity: 0.6; }
    .SubscribeEmail_container__39Bnz .SubscribeEmail_form__2MhKG .SubscribeEmail_input__2WCjX::-ms-input-placeholder {
      opacity: 0.6; }
    .SubscribeEmail_container__39Bnz .SubscribeEmail_form__2MhKG .SubscribeEmail_input__2WCjX::placeholder {
      opacity: 0.6; }
    .SubscribeEmail_container__39Bnz .SubscribeEmail_form__2MhKG .SubscribeEmail_input__2WCjX.SubscribeEmail_invalid__1TEg1 {
      border-bottom-color: #E31E24; }
  .SubscribeEmail_container__39Bnz .SubscribeEmail_form__2MhKG button {
    height: 48px; }
  .SubscribeEmail_container__39Bnz .SubscribeEmail_privacy__206at {
    margin-top: 40px;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF; }
    .SubscribeEmail_container__39Bnz .SubscribeEmail_privacy__206at a {
      color: #E31E24;
      text-decoration: none; }
  .SubscribeEmail_container__39Bnz .SubscribeEmail_success__2c5wg {
    font-size: 28px;
    color: #E31E24; }
  @media (max-width: 1400px) {
    .SubscribeEmail_container__39Bnz .SubscribeEmail_title__214yl {
      font-size: 42px; }
    .SubscribeEmail_container__39Bnz .SubscribeEmail_success__2c5wg {
      font-size: 24px; } }

@media (max-width: 1400px) and (max-width: 835px) {
  .SubscribeEmail_container__39Bnz .SubscribeEmail_form__2MhKG {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .SubscribeEmail_container__39Bnz .SubscribeEmail_form__2MhKG .SubscribeEmail_input__2WCjX {
      margin: 0 0 30px 0;
      width: 286px; }
  .SubscribeEmail_container__39Bnz .SubscribeEmail_privacy__206at {
    width: 320px; } }

.Modal_item__2iMkN {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .Modal_item__2iMkN img {
    margin-bottom: 10px; }

.BaseModal_modalContainer__3oum3 {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6); }
  .BaseModal_modalContainer__3oum3 .BaseModal_modal__20GAB {
    position: relative !important;
    background: #FFFFFF;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.45);
    outline: none;
    display: flex;
    flex-direction: column;
    max-width: 672px;
    border-radius: 10px;
    padding: 48px; }
    .BaseModal_modalContainer__3oum3 .BaseModal_modal__20GAB .BaseModal_title__2CgGy {
      font-family: 'Lato', sans-serif;
      font-size: 34px;
      line-height: 41px;
      font-style: normal;
      text-align: center;
      color: #000000; }
    .BaseModal_modalContainer__3oum3 .BaseModal_modal__20GAB .BaseModal_content__1sFEw {
      font-style: normal;
      font-weight: normal;
      line-height: 21px;
      font-size: 14px;
      color: #000000;
      margin: 20px 0 30px; }
    .BaseModal_modalContainer__3oum3 .BaseModal_modal__20GAB.BaseModal_centered__33top {
      align-items: center;
      justify-content: center;
      width: 540px;
      padding: 60px 100px 50px;
      border-radius: 0; }
      .BaseModal_modalContainer__3oum3 .BaseModal_modal__20GAB.BaseModal_centered__33top button {
        margin-right: 0 !important; }
      .BaseModal_modalContainer__3oum3 .BaseModal_modal__20GAB.BaseModal_centered__33top .BaseModal_title__2CgGy {
        line-height: 28px;
        font-size: 19px;
        font-weight: bold; }
      .BaseModal_modalContainer__3oum3 .BaseModal_modal__20GAB.BaseModal_centered__33top .BaseModal_content__1sFEw {
        text-align: center; }

.Events_container__2TXxG {
  scroll-snap-align: start;
  background-color: #F4F4F4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 96px 0; }
  .Events_container__2TXxG .Events_inner__3cZiO {
    width: 1272px;
    display: flex;
    flex-direction: column; }
  .Events_container__2TXxG .Events_loader__1VT8D {
    height: 288px; }
    .Events_container__2TXxG .Events_loader__1VT8D svg {
      margin: 40px 0 0;
      width: 160px;
      height: 160px; }
  .Events_container__2TXxG .Events_items__2eolp {
    display: flex;
    flex-wrap: wrap; }
  .Events_container__2TXxG h2 {
    font-weight: 600;
    line-height: normal;
    font-size: 42px;
    color: #000000;
    margin: 0;
    font-family: 'PT Mono', monospace; }
  .Events_container__2TXxG .Events_button__3Vx1q {
    margin-top: 94px;
    align-self: center; }
  @media (max-width: 1400px) {
    .Events_container__2TXxG {
      padding: 65px 20px; }
      .Events_container__2TXxG .Events_inner__3cZiO .Events_items__2eolp {
        width: 795px; }
      .Events_container__2TXxG .Events_button__3Vx1q {
        margin-top: 60px; } }
  @media (max-width: 1400px) and (max-width: 833px) {
    .Events_container__2TXxG {
      padding: 50px 12px; }
      .Events_container__2TXxG .Events_button__3Vx1q {
        margin-top: 35px; }
      .Events_container__2TXxG .Events_inner__3cZiO {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .Events_container__2TXxG .Events_inner__3cZiO h2 {
          font-weight: 300;
          line-height: normal;
          font-size: 28px;
          text-align: center; }
        .Events_container__2TXxG .Events_inner__3cZiO .Events_items__2eolp {
          flex-direction: column;
          align-items: center; }
          .Events_container__2TXxG .Events_inner__3cZiO .Events_items__2eolp .Events_item__28s_V {
            margin-right: 0 !important; } }

.Item_container__8g6bE {
  width: 288px;
  background-color: #fff;
  cursor: pointer;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .Item_container__8g6bE > div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: flex-start; }
  .Item_container__8g6bE:not(:nth-child(4n)) {
    margin-right: 40px; }
  .Item_container__8g6bE img {
    max-width: 288px !important; }
  .Item_container__8g6bE .Item_content__2xbDT {
    padding: 30px 20px 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 210px; }
    .Item_container__8g6bE .Item_content__2xbDT .Item_type__3XMRE {
      position: absolute;
      top: 26px;
      right: 8px;
      width: 36px; }
    .Item_container__8g6bE .Item_content__2xbDT .Item_title__10VN7 {
      padding-right: 40px;
      font-weight: bold;
      line-height: 25px;
      font-size: 15px;
      color: #000000;
      margin-top: 0;
      margin-bottom: 14px;
      text-overflow: ellipsis;
      overflow: hidden; }
    .Item_container__8g6bE .Item_content__2xbDT .Item_description__13WW9 {
      line-height: 21px;
      font-size: 13px;
      color: #000000;
      margin-top: 0;
      margin-bottom: 20px;
      text-overflow: ellipsis;
      overflow: hidden; }
    .Item_container__8g6bE .Item_content__2xbDT .Item_info__3QOv8 {
      margin-top: auto; }
      .Item_container__8g6bE .Item_content__2xbDT .Item_info__3QOv8 span {
        line-height: 23px;
        font-size: 14px;
        color: #474747;
        margin-right: 10px; }
      .Item_container__8g6bE .Item_content__2xbDT .Item_info__3QOv8 .Item_users__3kvRB:before {
        background-image: url(/static/media/user.d687b8da.svg);
        background-repeat: no-repeat;
        display: inline-block;
        width: 12px;
        height: 15px;
        content: "";
        margin-right: 10px;
        background-position-y: 4px; }
  @media (max-width: 1400px) {
    .Item_container__8g6bE {
      width: 245px; }
      .Item_container__8g6bE img {
        max-width: 245px !important; }
      .Item_container__8g6bE:not(:nth-child(4n)) {
        margin-right: -1px; }
      .Item_container__8g6bE:not(:nth-child(3n)) {
        margin-right: 30px; } }
  @media (max-width: 1400px) and (max-width: 833px) {
    .Item_container__8g6bE {
      width: 350px;
      margin-right: 0 !important;
      margin-top: 35px; }
      .Item_container__8g6bE img {
        max-width: 350px !important; }
      .Item_container__8g6bE .Item_content__2xbDT {
        height: unset; } }

.EventPicture_container__xqPxb {
  position: relative; }
  .EventPicture_container__xqPxb .EventPicture_icon__2CVH1 {
    position: absolute;
    left: 14px;
    bottom: 16px;
    width: 56px;
    height: 56px; }
  .EventPicture_container__xqPxb .EventPicture_picture__2cWbE {
    max-width: 400px; }
  .EventPicture_container__xqPxb.EventPicture_small__3acAU .EventPicture_picture__2cWbE {
    max-height: 190px; }
  .EventPicture_container__xqPxb.EventPicture_big__2cCQ_ .EventPicture_picture__2cWbE {
    max-width: 700px; }
  @media (max-width: 1400px) {
    .EventPicture_container__xqPxb.EventPicture_small__3acAU .EventPicture_picture__2cWbE {
      max-height: 162px; } }
  @media (max-width: 1200px) {
    .EventPicture_container__xqPxb .EventPicture_picture__2cWbE {
      max-width: unset !important;
      width: 100%;
      margin: 0 !important; } }

@media (max-width: 1200px) and (max-width: 833px) {
  .EventPicture_container__xqPxb.EventPicture_small__3acAU .EventPicture_picture__2cWbE {
    max-height: 235px; } }
  @media (min-width: 1201px) {
    .EventPicture_container__xqPxb.EventPicture_hideForDesktop__3OFUD {
      display: none; } }

.Filter_container__1_tsX {
  display: flex;
  margin-top: 27px; }
  .Filter_container__1_tsX span {
    line-height: 28px;
    font-size: 16px;
    color: #919191;
    margin-right: 12px; }
  .Filter_container__1_tsX ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
  @media (max-width: 1400px) {
    .Filter_container__1_tsX ul li, .Filter_container__1_tsX span {
      line-height: 25px;
      font-size: 14px; } }

.Filter_select__1uu2s {
  width: 350px;
  margin-top: 30px; }
  .Filter_select__1uu2s > div:first-of-type {
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    border-radius: 1px; }
  .Filter_select__1uu2s * {
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    font-size: 13px;
    color: #000000; }

.Item_container__2qO38 {
  line-height: 28px;
  font-size: 16px;
  cursor: pointer;
  color: #919191;
  display: inline; }
  .Item_container__2qO38:not(:last-child):after {
    content: "|";
    margin-left: 12px;
    margin-right: 12px;
    color: #000000 !important; }
  .Item_container__2qO38.Item_active__2iyvN {
    color: #000; }
  .Item_container__2qO38:hover {
    color: #E31E24; }

.Loader_container__2N0hx {
  display: flex;
  align-items: center;
  justify-content: center; }
  .Loader_container__2N0hx svg {
    height: 120px;
    width: 120px;
    overflow: visible; }
  .Loader_container__2N0hx .Loader_circle__BRIjg {
    -webkit-transform-origin: 60px 60px;
            transform-origin: 60px 60px;
    fill: #E31E24;
    -webkit-animation: Loader_opacity__2OsAL 1.2s linear infinite;
            animation: Loader_opacity__2OsAL 1.2s linear infinite; }
    .Loader_container__2N0hx .Loader_circle__BRIjg:nth-child(12n + 1) {
      -webkit-animation-delay: -0.1s;
              animation-delay: -0.1s;
      -webkit-transform: rotate(-30deg) translate(5px, 5px) scale(0.9);
              transform: rotate(-30deg) translate(5px, 5px) scale(0.9); }
    .Loader_container__2N0hx .Loader_circle__BRIjg:nth-child(12n + 2) {
      -webkit-animation-delay: -0.2s;
              animation-delay: -0.2s;
      -webkit-transform: rotate(-60deg) translate(5px, 5px) scale(0.9);
              transform: rotate(-60deg) translate(5px, 5px) scale(0.9); }
    .Loader_container__2N0hx .Loader_circle__BRIjg:nth-child(12n + 3) {
      -webkit-animation-delay: -0.3s;
              animation-delay: -0.3s;
      -webkit-transform: rotate(-90deg) translate(5px, 5px) scale(0.9);
              transform: rotate(-90deg) translate(5px, 5px) scale(0.9); }
    .Loader_container__2N0hx .Loader_circle__BRIjg:nth-child(12n + 4) {
      -webkit-animation-delay: -0.4s;
              animation-delay: -0.4s;
      -webkit-transform: rotate(-120deg) translate(5px, 5px) scale(0.9);
              transform: rotate(-120deg) translate(5px, 5px) scale(0.9); }
    .Loader_container__2N0hx .Loader_circle__BRIjg:nth-child(12n + 5) {
      -webkit-animation-delay: -0.5s;
              animation-delay: -0.5s;
      -webkit-transform: rotate(-150deg) translate(5px, 5px) scale(0.9);
              transform: rotate(-150deg) translate(5px, 5px) scale(0.9); }
    .Loader_container__2N0hx .Loader_circle__BRIjg:nth-child(12n + 6) {
      -webkit-animation-delay: -0.6s;
              animation-delay: -0.6s;
      -webkit-transform: rotate(-180deg) translate(5px, 5px) scale(0.9);
              transform: rotate(-180deg) translate(5px, 5px) scale(0.9); }
    .Loader_container__2N0hx .Loader_circle__BRIjg:nth-child(12n + 7) {
      -webkit-animation-delay: -0.7s;
              animation-delay: -0.7s;
      -webkit-transform: rotate(-210deg) translate(5px, 5px) scale(0.9);
              transform: rotate(-210deg) translate(5px, 5px) scale(0.9); }
    .Loader_container__2N0hx .Loader_circle__BRIjg:nth-child(12n + 8) {
      -webkit-animation-delay: -0.8s;
              animation-delay: -0.8s;
      -webkit-transform: rotate(-240deg) translate(5px, 5px) scale(0.9);
              transform: rotate(-240deg) translate(5px, 5px) scale(0.9); }
    .Loader_container__2N0hx .Loader_circle__BRIjg:nth-child(12n + 9) {
      -webkit-animation-delay: -0.9s;
              animation-delay: -0.9s;
      -webkit-transform: rotate(-270deg) translate(5px, 5px) scale(0.9);
              transform: rotate(-270deg) translate(5px, 5px) scale(0.9); }
    .Loader_container__2N0hx .Loader_circle__BRIjg:nth-child(12n + 10) {
      -webkit-animation-delay: -1s;
              animation-delay: -1s;
      -webkit-transform: rotate(-300deg) translate(5px, 5px) scale(0.9);
              transform: rotate(-300deg) translate(5px, 5px) scale(0.9); }
    .Loader_container__2N0hx .Loader_circle__BRIjg:nth-child(12n + 11) {
      -webkit-animation-delay: -1.1s;
              animation-delay: -1.1s;
      -webkit-transform: rotate(-330deg) translate(5px, 5px) scale(0.9);
              transform: rotate(-330deg) translate(5px, 5px) scale(0.9); }
    .Loader_container__2N0hx .Loader_circle__BRIjg:nth-child(12n + 12) {
      -webkit-animation-delay: -1.2s;
              animation-delay: -1.2s;
      -webkit-transform: rotate(-360deg) translate(5px, 5px) scale(0.9);
              transform: rotate(-360deg) translate(5px, 5px) scale(0.9); }

@-webkit-keyframes Loader_opacity__2OsAL {
  3% {
    fill-opacity: 1; }
  75% {
    fill-opacity: 0; } }

@keyframes Loader_opacity__2OsAL {
  3% {
    fill-opacity: 1; }
  75% {
    fill-opacity: 0; } }

.Slider_container__2hqMN {
  width: 100%;
  position: relative;
  height: 600px;
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .Slider_container__2hqMN .Slider_arrow__srJ6I, .Slider_container__2hqMN .Slider_arrowLeft__1asZQ, .Slider_container__2hqMN .Slider_arrowRight__2hFQb {
    position: absolute;
    z-index: 2;
    height: 82px;
    width: 82px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABUUExURUdwTP///////////////////////////////////////////////////y4uLikpKcrKyn5+fiMjI+/v7zs7OyIiImRkZElJSSsrK6ampqmpqSAgIC1Y2mUAAAAbdFJOUwARLRsyHgMzLyILGCoGy9k/X+42q/dyk9RLScWBEsIAAADgSURBVDjLlVXLEoMgEIuCAipa37X8/3/20unoAjXNMZMxQ3Y3Ahc0reus1rZzbYMsvLLmBKt8UlZX2gjoqo51hTUJ2ELqKpNBdbV1Jgt3tv+hM8YRvsK9MDf4vKi2d0JbM8Zfc6/vhdoDUILsn49YqQCUghtCP0fCEmgk95rCEn+yQRtx4xr2iGxTQ9lC2OLxdIlH7mEdBdUhmfYSpkNkjmSKcx+Ga0iaF+asX9KafgwdDx14NMIjM0J6Kfg1oxeXPgX+uOhz5QuArxS+pOja+6NI+WoG4NVl7mWm7G9+H2/uHydDywuGqgAAAABJRU5ErkJggg==) center no-repeat;
    top: calc(50% - 41px);
    pointer-events: none; }
    .Slider_container__2hqMN .Slider_arrow__srJ6I.Slider_active__2DulR, .Slider_container__2hqMN .Slider_active__2DulR.Slider_arrowLeft__1asZQ, .Slider_container__2hqMN .Slider_active__2DulR.Slider_arrowRight__2hFQb {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFIAAABSCAMAAADw8nOpAAAAh1BMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABHcEwAAAAAAAAAAAAAAAADAwMAAAAAAAD7+/v+/v7u7u42Njb8/Pz5+fnW1tb19fXl5eX09PTq6up1dXWQkJC7u7u8vLz///8gICBZWVlLS0vl5eUyMjLPz898fHwpKSmWlpYhISG5ubm7u7tiNDdAAAAAIHRSTlMDCA4QAgwKBAEACQYVFBgSFvL8oh/m2Gm7h7STKjJLTCoPrE4AAALmSURBVFjDxdnbctsgEAZgWjt1DzK1pNiOj5Wcs5P3f74CLGJZkFAb7WQvcuEZffPDEhuQ+JGr76SyD4jx1lhZ/A83zIo8OA8qj4pBb95Tg6roFd3jglTEZkgCOmYBFbI9qEiK3lskyqtJUwyBi94aQkUkUvAXqR40RSLRg8B8gQpUh1JTpEXvaelnV53r0IRJSCt2oOW+obIsQr1JyU5EEbXnqBtVnjUoChqYIi0i8IYUQWMTk6FoQIN8ReVUhcYmJiNRRbSgZmaogNWoDpoyBRbnVATvd1egUnOOTRGGBFEPGkANLbuyLKBm8J0Zk4EIEQ24XBbFarWSUv0pCqNqFIIS05EQkooWLBSGa1UAGpkupiAhregiWvC822/Kqio3+93ZoS4omDimCENCZ6xowNNh3aBaH04WNabvEYopkiGVqCNKeayrhlRVH6U0QcGkMTsyHLYWVcRt2SSq3KqgxiRDxySE9OJMi7d101P1rTZn2HQxNRmHdOJd01t3nRnHxCSaSDWPQ6IxdY/QdMakC2knUnWmbgarVj2C6XQxMenG7UOu5LbJ1NYM3cX0I8dkEPJY5sjySGJS0o7bh6ybbNU+Jh55QEK7dchTlSerE8TsRg6k6U44bhXyQB6/3Ccm4mBj4pFrTSSmcinlmjx9317i3Gspl4nJDEhY5oU806cfHtvnOOZZjdwu9z4SplIt81309NNL+xp9uLPLXU9mTOLuqKncx4nertd3+tkeJjPsT0i67mwSDX5tX57IRxvcnwyZXOfP7eMDWe24P4OklMlVWV1aspSqzyQZBj5pewYW0fv1+vZPi4hhqZN/yD9T/UNO+LUx9Zcbw1cw3w8Fw8/Z1D+6DFsDpg3MlNsshs0g05aVY2M98faf4ZDCcZQac+Az2vgDH9exdOLDM8MRn+MiguO6JHep47XRlzocV08cF2QM13gcl40cV6IcF7cs18scl+AsV/UcLxR4XnuwvJz5yCukv7+/N/84xVjTAAAAAElFTkSuQmCC);
      cursor: pointer;
      pointer-events: initial; }
  .Slider_container__2hqMN .Slider_arrowLeft__1asZQ {
    left: 0;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1); }
  .Slider_container__2hqMN .Slider_arrowRight__2hFQb {
    right: 0; }
  @media (max-width: 1200px) {
    .Slider_container__2hqMN {
      height: 520px; }
      .Slider_container__2hqMN .Slider_arrow__srJ6I, .Slider_container__2hqMN .Slider_arrowLeft__1asZQ, .Slider_container__2hqMN .Slider_arrowRight__2hFQb {
        display: none; }
      .Slider_container__2hqMN .Slider_hint__s6HkN {
        position: absolute;
        z-index: 2;
        width: 54px;
        height: 37px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAAlCAYAAAAJBxroAAADcklEQVRYhd3ZWaiVVRQH8P2p3TBtgCaLLMuhemnQCqKH4hYhVEKUNhtBRNDcQ1FQZEVQEE0WQY9FSBlBD1GZQfXUIFoRNk/YZNJ4zcdf7FiXjqfjPecbjud4Fxz49vftvfb/v/dew16nSAMQzE4pLU8pjaaU5qeUZqSUtqSUPkwpvZRSWl0UxbZBYKskmIGHsBXP4RIcj3k4BTfiPfyYv+0qpA7CeryOI7r0PRub8ASmNDH5IWj8eGIPbMBTmNrjmIPxMR6oO/kSjGVytRR11p2P35pOpHBCEH4Ha3Er9o5vc/A7Tq068TipJQ3waNc9G39nO2p7fyReCOD3YzEuigX4CodFv2tjt8sdyX6SCv23ZUfR0p4aRLbGTu7X1r8I23o72tPwOS4tO/Gv6snDXfSvaQWF+/AJFkwwZjq+w7nRXoqvMVKGWN6xbViOfSr8pnfR/y0WxvO+cSyP6gHXFdgYO5Z38X3c0DOx1H8byydibjyfk51Ej+OmhFe8Mtqn4xfsWRZAX7wiPs3BN56X5ThWEtOm8VOB13B3FRCNxzGsHj9CkWH81euqR/z7HrdEO2cpf2JWkxgrCS7PaVIL2FexoguhbFdXR2r1Jk5q+fYsHh8GYtnD/Ywzor0odu2ADn2zkzgfn+EDnNWhz9wwmXnt33a64PrwakWAW4XH2gCP4l18g8smCsh5bNYxDMRGIptYlv6ztbFY/ePwCrbgpl5iVd7t2PVFO4VAFzD5ivIFdov2yohx2Rncg71K6rurjIftm0RcyteWawLYLDyarzNV5sRMbB633UGTWxyebmZD+q7Dun5ctaqAeQN3NKQr2+6XuLAJfXXBnIg/2rP6GvouDtvtPUHul+B5PNIQse1sd9DEFkSWP6chfWfip6Zsty6YJ/F0xbGHdniXywp3NgKujkTVKgfZY0sQOjCyjrH2cVE7yTFx/2Egdy9e7qFfjlkrYiFW7ShPbNJ2a0nc1nMqddoOgI5ErNocJYaFE82H+WG7hw8DuZuj9Fa0vR+N/HJdmewiCkHP9AVsGcHukTOe1wbwGFxQNqsI2106KD7bSRSScglh2lAAakoiyH6EqyYHoxaJPyJ+6FbO2yUFb+H2yUjsZPyWC6tDAKdZwYt4cDJx+ldwdGQY/8sHy0j9fwsblqIoNqaUVqaUqmcPKaV/AIBuonbfDy4XAAAAAElFTkSuQmCC);
        left: 20px;
        bottom: 0; } }

.Slide_container__1xhG3 {
  height: 600px; }
  .Slide_container__1xhG3 .Slide_left__3hvLP {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative; }
    .Slide_container__1xhG3 .Slide_left__3hvLP .Slide_text__3wQne {
      width: 620px;
      text-align: center;
      font-family: 'PT Sans', sans-serif;
      font-size: 24px;
      line-height: 1.33; }
      .Slide_container__1xhG3 .Slide_left__3hvLP .Slide_text__3wQne b, .Slide_container__1xhG3 .Slide_left__3hvLP .Slide_text__3wQne a {
        font-weight: normal;
        color: #cf3630; }
      .Slide_container__1xhG3 .Slide_left__3hvLP .Slide_text__3wQne h5 {
        margin: 0;
        font-size: 32px; }
      .Slide_container__1xhG3 .Slide_left__3hvLP .Slide_text__3wQne ul {
        text-align: left;
        font-size: 16px;
        line-height: 1.75;
        list-style: none;
        margin-left: 20px;
        margin-top: 50px;
        width: 630px; }
    .Slide_container__1xhG3 .Slide_left__3hvLP .Slide_count__y9e2D {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      font-size: 12px;
      line-height: 2.67; }
  .Slide_container__1xhG3.Slide_hasRight__1fvdj {
    display: flex;
    justify-content: center; }
    .Slide_container__1xhG3.Slide_hasRight__1fvdj .Slide_left__3hvLP {
      justify-content: start;
      padding-top: 155px; }
      .Slide_container__1xhG3.Slide_hasRight__1fvdj .Slide_left__3hvLP .Slide_text__3wQne {
        width: 350px;
        text-align: left; }
      .Slide_container__1xhG3.Slide_hasRight__1fvdj .Slide_left__3hvLP .Slide_count__y9e2D {
        text-align: left; }
    .Slide_container__1xhG3.Slide_hasRight__1fvdj .Slide_right__1XBqa {
      margin-left: 45px;
      margin-top: 95px;
      max-width: 480px; }
  @media (max-width: 1200px) {
    .Slide_container__1xhG3 {
      height: 520px; }
      .Slide_container__1xhG3 .Slide_left__3hvLP .Slide_text__3wQne {
        font-size: 16px;
        line-height: normal;
        width: auto !important;
        max-width: 400px;
        padding: 0 15px; }
        .Slide_container__1xhG3 .Slide_left__3hvLP .Slide_text__3wQne h5 {
          font-size: 24px; }
        .Slide_container__1xhG3 .Slide_left__3hvLP .Slide_text__3wQne ul {
          width: 300px; }
      .Slide_container__1xhG3.Slide_hasRight__1fvdj {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .Slide_container__1xhG3.Slide_hasRight__1fvdj .Slide_left__3hvLP {
          padding: 0;
          z-index: 2; }
          .Slide_container__1xhG3.Slide_hasRight__1fvdj .Slide_left__3hvLP .Slide_text__3wQne {
            text-align: center;
            max-height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center; }
          .Slide_container__1xhG3.Slide_hasRight__1fvdj .Slide_left__3hvLP .Slide_count__y9e2D {
            text-align: center; }
        .Slide_container__1xhG3.Slide_hasRight__1fvdj .Slide_right__1XBqa {
          margin: 20px 0 0;
          order: -1;
          height: 310px;
          flex-shrink: 0;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          position: relative; }
          .Slide_container__1xhG3.Slide_hasRight__1fvdj .Slide_right__1XBqa img {
            height: 420px; }
          .Slide_container__1xhG3.Slide_hasRight__1fvdj .Slide_right__1XBqa:after {
            content: "";
            position: absolute;
            top: 100px;
            display: block;
            height: 320px;
            width: calc(100% + 2px);
            background: -webkit-linear-gradient(rgba(32, 32, 32, 0), #202020 62%);
            background: linear-gradient(rgba(32, 32, 32, 0), #202020 62%); }
      .Slide_container__1xhG3.Slide_web__2m2GP .Slide_right__1XBqa img {
        height: 280px; }
      .Slide_container__1xhG3.Slide_web__2m2GP .Slide_right__1XBqa:after {
        display: none; } }


.EventCard_container__2Y1bi {
  width: 708px;
  margin: 160px 180px 105px; }
  .EventCard_container__2Y1bi img {
    width: 100%; }
  .EventCard_container__2Y1bi h1 {
    margin-top: 32px;
    margin-bottom: 12px; }
  .EventCard_container__2Y1bi .EventCard_subtitle__3A5KO {
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    font-size: 14px;
    color: #474747;
    margin-right: 10px; }
  .EventCard_container__2Y1bi .EventCard_count__3kDbN {
    line-height: 23px;
    font-size: 14px;
    color: #474747;
    margin-right: 10px; }
    .EventCard_container__2Y1bi .EventCard_count__3kDbN:before {
      background-image: url(/static/media/user.d687b8da.svg);
      background-repeat: no-repeat;
      display: inline-block;
      width: 12px;
      height: 15px;
      content: "";
      margin-right: 10px;
      background-position-y: 4px; }
  .EventCard_container__2Y1bi h2 {
    margin: 30px 0 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    font-size: 16px;
    color: #000000; }
    .EventCard_container__2Y1bi h2 span {
      font-style: normal;
      font-weight: normal;
      line-height: 28px;
      font-size: 16px;
      color: #000000;
      margin: 0 12px; }
      .EventCard_container__2Y1bi h2 span.EventCard_red__3Lb3T {
        margin: 0;
        color: #E31E24; }
  .EventCard_container__2Y1bi .EventCard_description__NK6r- {
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    font-size: 16px;
    color: #000000; }
  @media (max-width: 1200px) {
    .EventCard_container__2Y1bi {
      width: 100%;
      margin: 0;
      display: flex;
      flex-direction: column; }
      .EventCard_container__2Y1bi h1 {
        margin: 0;
        order: -1; }
      .EventCard_container__2Y1bi .EventCard_title__jwsK1 {
        margin-top: 30px;
        margin-left: 12px;
        font-size: 28px;
        margin-bottom: 0; }
      .EventCard_container__2Y1bi p {
        margin-left: 12px; }
      .EventCard_container__2Y1bi .EventCard_subtitle__3A5KO {
        margin: 15px 12px 5px;
        line-height: 21px;
        font-size: 12px; }
      .EventCard_container__2Y1bi h2 {
        margin: 15px 12px 15px;
        line-height: 21px;
        font-size: 12px; }
        .EventCard_container__2Y1bi h2 span {
          line-height: 21px;
          font-size: 12px;
          margin: 0 8px; }
          .EventCard_container__2Y1bi h2 span.EventCard_red__3Lb3T {
            margin: 0; }
        .EventCard_container__2Y1bi h2 span:nth-last-child(2) {
          display: none; }
        .EventCard_container__2Y1bi h2 span:last-of-type {
          display: block; }
      .EventCard_container__2Y1bi .EventCard_description__NK6r- {
        margin: 15px 12px 5px;
        font-weight: normal;
        line-height: 25px;
        font-size: 14px; }
      .EventCard_container__2Y1bi .EventCard_location__7TIWq {
        margin: 15px 12px 30px;
        line-height: 21px;
        font-size: 12px; } }

.PageHeader_container__1PSZJ {
  margin: 0;
  font-family: 'PT Mono', monospace;
  line-height: normal;
  font-size: 72px;
  color: #000000; }
  @media (max-width: 1200px) {
    .PageHeader_container__1PSZJ {
      background-color: black;
      padding: 20px 68px 20px 12px;
      color: #fff;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      font-size: 28px; } }

.MobileHeader_container__2dCpJ {
  display: none;
  margin: 0 0 30px; }
  @media (max-width: 1200px) {
    .MobileHeader_container__2dCpJ {
      display: block;
      font-family: 'PT Mono', monospace;
      font-weight: 300;
      line-height: normal;
      font-size: 28px;
      color: #000000; } }

.Header_header__4Dlpu {
  position: fixed;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 50px;
  top: 0; }
  .Header_header__4Dlpu p {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-right: 18px;
    cursor: pointer; }
    .Header_header__4Dlpu p.Header_active__3D7wm {
      color: #E31E24; }
  .Header_header__4Dlpu .Header_left__dF5oI {
    display: flex; }
    .Header_header__4Dlpu .Header_left__dF5oI a {
      display: flex; }
    .Header_header__4Dlpu .Header_left__dF5oI .Header_logo__2WeEV {
      margin-right: 52px; }
    .Header_header__4Dlpu .Header_left__dF5oI .Header_mobileLogo__1Sg9L {
      display: none; }
  .Header_header__4Dlpu * {
    color: #fff; }
  .Header_header__4Dlpu.Header_white__3t-G8 {
    background-color: #fff;
    -webkit-animation: Header_fromUp__1EwDn .3s linear 0s;
            animation: Header_fromUp__1EwDn .3s linear 0s; }
    .Header_header__4Dlpu.Header_white__3t-G8 p {
      color: #000; }
      .Header_header__4Dlpu.Header_white__3t-G8 p.Header_active__3D7wm {
        color: #E31E24; }
    .Header_header__4Dlpu.Header_white__3t-G8 .Header_languages__fALzF li {
      color: black; }
  .Header_header__4Dlpu .Header_right__1kzsy {
    display: flex;
    align-items: center; }
  .Header_header__4Dlpu .Header_languages__fALzF {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .Header_header__4Dlpu .Header_languages__fALzF li {
      display: inline;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 14px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      opacity: 0.4; }
      .Header_header__4Dlpu .Header_languages__fALzF li:not(:last-of-type) {
        margin-right: 10px; }
      .Header_header__4Dlpu .Header_languages__fALzF li:hover {
        opacity: 0.7; }
      .Header_header__4Dlpu .Header_languages__fALzF li.Header_active__3D7wm {
        opacity: 1; }

.Header_menu__3Z40W {
  display: none; }

.Header_header__4Dlpu.Header_isMobile__2oyD6, .Header_header__4Dlpu.Header_white__3t-G8.Header_isMobile__2oyD6 {
  z-index: 4;
  height: 100vh;
  width: 320px;
  background-color: black;
  display: flex;
  right: -320px;
  flex-direction: column;
  -webkit-animation: none;
          animation: none;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .Header_header__4Dlpu.Header_isMobile__2oyD6 p, .Header_header__4Dlpu.Header_white__3t-G8.Header_isMobile__2oyD6 p {
    font-weight: 600;
    line-height: normal;
    font-size: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #E5E5E5; }
  .Header_header__4Dlpu.Header_isMobile__2oyD6 .Header_left__dF5oI, .Header_header__4Dlpu.Header_white__3t-G8.Header_isMobile__2oyD6 .Header_left__dF5oI {
    flex-direction: column; }
    .Header_header__4Dlpu.Header_isMobile__2oyD6 .Header_left__dF5oI .Header_logo__2WeEV, .Header_header__4Dlpu.Header_white__3t-G8.Header_isMobile__2oyD6 .Header_left__dF5oI .Header_logo__2WeEV {
      display: none; }
    .Header_header__4Dlpu.Header_isMobile__2oyD6 .Header_left__dF5oI .Header_mobileLogo__1Sg9L, .Header_header__4Dlpu.Header_white__3t-G8.Header_isMobile__2oyD6 .Header_left__dF5oI .Header_mobileLogo__1Sg9L {
      display: block;
      width: 154px;
      margin-top: 88px;
      margin-bottom: 60px; }
  .Header_header__4Dlpu.Header_isMobile__2oyD6 .Header_right__1kzsy, .Header_header__4Dlpu.Header_white__3t-G8.Header_isMobile__2oyD6 .Header_right__1kzsy {
    display: flex;
    flex-direction: column;
    margin-bottom: 140px; }
    .Header_header__4Dlpu.Header_isMobile__2oyD6 .Header_right__1kzsy button, .Header_header__4Dlpu.Header_white__3t-G8.Header_isMobile__2oyD6 .Header_right__1kzsy button {
      width: 100%;
      margin-bottom: 20px; }
  .Header_header__4Dlpu.Header_isMobile__2oyD6.Header_showOnMobile__1aHHu, .Header_header__4Dlpu.Header_white__3t-G8.Header_isMobile__2oyD6.Header_showOnMobile__1aHHu {
    right: 0; }
  .Header_header__4Dlpu.Header_isMobile__2oyD6 .Header_close__3swkc, .Header_header__4Dlpu.Header_white__3t-G8.Header_isMobile__2oyD6 .Header_close__3swkc {
    position: absolute;
    top: 25px;
    right: 25px; }
  .Header_header__4Dlpu.Header_isMobile__2oyD6 .Header_languages__fALzF, .Header_header__4Dlpu.Header_white__3t-G8.Header_isMobile__2oyD6 .Header_languages__fALzF {
    margin-bottom: 30px; }
    .Header_header__4Dlpu.Header_isMobile__2oyD6 .Header_languages__fALzF li, .Header_header__4Dlpu.Header_white__3t-G8.Header_isMobile__2oyD6 .Header_languages__fALzF li {
      color: #fff; }

.Header_menu__3Z40W.Header_isMobile__2oyD6 {
  cursor: pointer;
  position: absolute;
  z-index: 3;
  display: block;
  right: 12px;
  top: 12px; }

@-webkit-keyframes Header_fromUp__1EwDn {
  0% {
    top: -80px; }
  100% {
    top: 0; } }

@keyframes Header_fromUp__1EwDn {
  0% {
    top: -80px; }
  100% {
    top: 0; } }

.Authentication_container__1ly5K {
  display: flex; }
  .Authentication_container__1ly5K p.Authentication_red__Lb9We {
    color: #E31E24; }
  .Authentication_container__1ly5K .Authentication_modal__XqVzi {
    max-width: 672px;
    background: #FFFFFF;
    border-radius: 10px; }

.Input_container__RFKs2 {
  display: flex;
  flex-direction: column;
  position: relative; }
  .Input_container__RFKs2 label {
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    font-size: 14px;
    color: #000000; }
  .Input_container__RFKs2 input {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 1px;
    height: 40px;
    padding-left: 7px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    font-size: 13px;
    color: #000000; }
    .Input_container__RFKs2 input::-webkit-input-placeholder {
      color: #9F9F9F; }
    .Input_container__RFKs2 input::-ms-input-placeholder {
      color: #9F9F9F; }
    .Input_container__RFKs2 input::placeholder {
      color: #9F9F9F; }
    .Input_container__RFKs2 input.Input_invalid__1HxTD {
      border-color: #E31E24;
      outline: none; }
    .Input_container__RFKs2 input:disabled {
      background: #F6F6F6; }
  .Input_container__RFKs2 .Input_error__QBC9u {
    position: absolute;
    bottom: -36px;
    color: #E31E24; }

.BaseForm_container__Z8NDO .BaseForm_subtitle__2DEZL {
  margin-top: 52px;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  line-height: 30px; }

.BaseForm_container__Z8NDO .BaseForm_row__szOTb {
  margin-top: 30px; }

.BaseForm_container__Z8NDO .BaseForm_error__MIOh2 {
  display: flex;
  align-items: center;
  color: #E31E24;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 0;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .BaseForm_container__Z8NDO .BaseForm_error__MIOh2.BaseForm_visible__tdHYG {
    opacity: 1;
    height: 40px; }

.BaseForm_container__Z8NDO .BaseForm_buttons__2QShy {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.Captcha_invalid___0skf {
  position: relative; }
  .Captcha_invalid___0skf:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    border: 1px solid #E31E24;
    width: 300px;
    height: 75px;
    pointer-events: none; }

.Modal_container__21xwl .Modal_modal__3ijKq {
  width: 672px;
  border-radius: 10px;
  padding: 48px 48px 28px; }
  .Modal_container__21xwl .Modal_modal__3ijKq > div {
    width: 100%; }

.SignIn_right__dbHrV {
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: #E31E24; }

.EventForm_container__159gu {
  margin: 140px 0 80px;
  display: flex;
  justify-content: center; }
  .EventForm_container__159gu .EventForm_content__2FiXf h1 {
    margin: 0 0 40px; }
  @media (max-width: 1200px) {
    .EventForm_container__159gu {
      margin: 0 0 55px; }
      .EventForm_container__159gu .EventForm_content__2FiXf {
        width: 100%; }
        .EventForm_container__159gu .EventForm_content__2FiXf h1 {
          margin: 0; } }

.Form_form__w9wHq {
  display: flex; }
  .Form_form__w9wHq .Form_fields__3hckk {
    width: 580px;
    padding: 20px 80px;
    background: #F6F6F6; }
    .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW {
      margin-top: 25px;
      display: flex; }
      .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW > div {
        width: 100%; }
        .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW > div:not(:last-of-type) {
          margin-right: 10px; }
      .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW small {
        font-style: normal;
        font-weight: normal;
        line-height: 19px;
        font-size: 13px;
        color: #9F9F9F; }
      .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW .Form_location__1O-FE {
        background-image: url(/static/media/address.291e470b.svg);
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: calc(100% - 20px);
        padding-right: 50px; }
      .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW:nth-child(5) > div:nth-child(3) {
        width: 50%;
        min-width: 55px; }
  .Form_form__w9wHq .Form_cover__3JwYo {
    margin-left: 50px;
    width: 400px; }
    .Form_form__w9wHq .Form_cover__3JwYo .Form_title__gSLfU {
      margin: 45px 0 20px;
      font-family: 'Futura PT', sans-serif;
      line-height: 23px;
      font-size: 16px;
      color: #000000; }
    .Form_form__w9wHq .Form_cover__3JwYo input[type="file"] {
      display: none; }
    .Form_form__w9wHq .Form_cover__3JwYo button {
      margin: 30px 0 20px; }
    .Form_form__w9wHq .Form_cover__3JwYo small {
      display: block;
      font-style: normal;
      font-weight: normal;
      line-height: 21px;
      font-size: 14px;
      color: #9F9F9F; }
  @media (max-width: 1200px) {
    .Form_form__w9wHq {
      flex-direction: column-reverse; }
      .Form_form__w9wHq .Form_fields__3hckk {
        width: 100%;
        padding: 0 10px;
        background-color: transparent; }
        .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW {
          margin: 0; }
          .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW:nth-child(1), .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW:nth-child(2) {
            display: flex;
            flex-direction: column; }
          .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW label {
            margin-top: 21px;
            margin-bottom: 8px; }
          .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW:nth-child(4) > div {
            width: 100% !important; }
          .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW:nth-child(5) > div:nth-child(1) {
            width: calc(50% - 5px);
            flex-shrink: 0; }
          .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW:nth-child(5) > div:nth-child(2) {
            min-width: 80px; }
            .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW:nth-child(5) > div:nth-child(2) label {
              white-space: nowrap; }
          .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW:nth-child(9), .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW:nth-child(11) {
            margin-top: 21px; }
          .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW:nth-child(10) {
            margin-top: 35px; }
          .Form_form__w9wHq .Form_fields__3hckk .Form_row__3T3oW small {
            white-space: unset !important; }
      .Form_form__w9wHq .Form_cover__3JwYo {
        margin: 0;
        width: 100%;
        position: relative; }
        .Form_form__w9wHq .Form_cover__3JwYo .Form_title__gSLfU {
          display: none; }
        .Form_form__w9wHq .Form_cover__3JwYo button {
          margin: 0;
          background-color: transparent;
          color: transparent;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0; }
        .Form_form__w9wHq .Form_cover__3JwYo:after {
          content: url(/static/media/photo.2fcb87ab.svg);
          position: absolute;
          top: calc(50% - 25px);
          left: calc(50% - 29.5px);
          pointer-events: none; }
        .Form_form__w9wHq .Form_cover__3JwYo small {
          display: none; } }

.SelectBox_container__3fs08 {
  display: flex;
  flex-direction: column; }
  .SelectBox_container__3fs08 label {
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    font-size: 14px;
    color: #000000; }
  .SelectBox_container__3fs08 .SelectBox_select__3NkrE > div:first-of-type {
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    border-radius: 1px; }
  .SelectBox_container__3fs08 .SelectBox_select__3NkrE * {
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    font-size: 13px;
    color: #000000; }
  .SelectBox_container__3fs08 .SelectBox_select__3NkrE.SelectBox_disabled__1MLFl > div:first-of-type {
    background: #F6F6F6; }

.DatePicker_container__2II-E {
  display: flex;
  flex-direction: column; }
  .DatePicker_container__2II-E label {
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    font-size: 14px;
    color: #000000; }
  .DatePicker_container__2II-E input {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 1px;
    height: 40px;
    width: 100%;
    padding-left: 7px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    font-size: 13px;
    color: #000000;
    background-image: url(/static/media/icon.e89983ac.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: calc(100% - 13px); }
    .DatePicker_container__2II-E input::-webkit-input-placeholder {
      color: #9F9F9F; }
    .DatePicker_container__2II-E input::-ms-input-placeholder {
      color: #9F9F9F; }
    .DatePicker_container__2II-E input::placeholder {
      color: #9F9F9F; }
    .DatePicker_container__2II-E input.DatePicker_invalid__2jJQ- {
      border-color: #E31E24;
      outline: none; }
    .DatePicker_container__2II-E input.DatePicker_disabled__1EVOY {
      background: #F6F6F6; }

.TextArea_container___rB6I {
  display: flex;
  flex-direction: column; }
  .TextArea_container___rB6I label {
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    font-size: 14px;
    color: #000000; }
  .TextArea_container___rB6I textarea {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 1px;
    height: 120px;
    padding: 15px 10px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    font-size: 13px;
    color: #000000;
    resize: vertical; }
    .TextArea_container___rB6I textarea::-webkit-input-placeholder {
      color: #9F9F9F; }
    .TextArea_container___rB6I textarea::-ms-input-placeholder {
      color: #9F9F9F; }
    .TextArea_container___rB6I textarea::placeholder {
      color: #9F9F9F; }
    .TextArea_container___rB6I textarea.TextArea_invalid__3-uJN {
      border-color: #E31E24;
      outline: none; }

.File_container__27N4y {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .File_container__27N4y img {
    max-width: 400px; }
  @media (max-width: 1200px) {
    .File_container__27N4y img {
      max-width: 100%;
      width: 100%; } }

.Progress_container__225Ra {
  margin: 140px 0 80px;
  display: flex;
  justify-content: center; }
  .Progress_container__225Ra .Progress_content__1hoYH {
    max-width: 1080px; }
    .Progress_container__225Ra .Progress_content__1hoYH iframe {
      margin-top: 10px; }
    .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA {
      margin-top: 60px;
      display: flex; }
      .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- {
        width: 565px; }
        .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- > div {
          margin: 10px 0;
          font-style: normal;
          font-weight: normal;
          line-height: 21px;
          font-size: 14px;
          color: #000000; }
        .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_label__CtO7B {
          font-style: normal;
          font-weight: 600;
          line-height: 21px;
          font-size: 14px;
          color: #000000; }
        .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_big__3Fsgs {
          line-height: 64px;
          font-size: 64px;
          margin-bottom: 20px;
          display: flex;
          align-items: center; }
          .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_big__3Fsgs .Progress_arrows__1X9c2 {
            margin-left: 12px;
            display: inline-flex;
            flex-direction: column; }
            .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_big__3Fsgs .Progress_arrows__1X9c2 img:first-of-type {
              margin-bottom: 12px; }
        .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_separator__49aUP {
          margin: 30px 0;
          border: 1px solid #E5E5E5; }
        .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_warning__3hblO {
          margin-top: -5px;
          line-height: 18px;
          font-size: 12px;
          color: #E31E24; }
        .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_uploaded__F-7bY {
          display: flex; }
          .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_uploaded__F-7bY .Progress_loader__3dvB5 {
            margin-left: 10px; }
            .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_uploaded__F-7bY .Progress_loader__3dvB5 svg {
              width: 20px;
              height: 20px; }
            .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_uploaded__F-7bY .Progress_loader__3dvB5 circle {
              fill: black; }
        .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_load__2BX-u {
          margin-top: 30px; }
          .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_load__2BX-u small {
            display: block;
            margin-top: 16px;
            line-height: 19px;
            font-size: 13px;
            color: #919191; }
        .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_bottomWarning__3EVqa {
          margin-top: 30px;
          line-height: 19px;
          font-size: 13px;
          color: #919191; }
        .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_bottom__2eGxx {
          margin-top: 30px;
          display: flex;
          flex-direction: column; }
          .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_bottom__2eGxx .Progress_row__r3QRM {
            margin-bottom: 20px;
            display: flex; }
      .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_right__1JbkU {
        margin-left: 45px;
        width: 440px;
        display: inline-block; }
        .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_right__1JbkU > span {
          display: inline-block;
          position: relative;
          cursor: pointer; }
          .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_right__1JbkU > span .Progress_playSvg__2LqNN {
            position: absolute;
            top: calc(50% - 124px); }
          .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_right__1JbkU > span .Progress_previewLoader__3pD_k {
            position: absolute;
            top: calc(50% - 60px);
            left: calc(50% - 60px); }
  @media (max-width: 1200px) {
    .Progress_container__225Ra {
      margin: 0 0 30px; }
      .Progress_container__225Ra .Progress_content__1hoYH {
        width: 100%;
        max-width: unset !important; }
        .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA {
          margin: 30px;
          flex-direction: column; }
          .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- {
            width: 100%; }
            .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- > div {
              margin: 1px 0;
              line-height: 18px;
              font-size: 12px; }
            .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_label__CtO7B {
              margin-bottom: 5px; }
            .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_big__3Fsgs {
              font-size: 48px; }
            .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_lengthAndPrice__2d3kw {
              display: flex;
              align-items: center; }
              .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_lengthAndPrice__2d3kw .Progress_label__CtO7B {
                display: none; }
              .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_lengthAndPrice__2d3kw .Progress_big__3Fsgs:nth-child(3) {
                margin-left: 30px;
                font-size: 18px; }
            .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_load__2BX-u small {
              line-height: 19px;
              font-size: 11px; }
            .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_bottom__2eGxx {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start; }
            .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_left__TaeY- .Progress_separator__49aUP {
              border: none; }
          .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_right__1JbkU {
            margin: -30px 0 30px -30px;
            width: 100vw;
            order: -1; }
            .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_right__1JbkU > span {
              width: 100%; }
              .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_right__1JbkU > span .Progress_playSvg__2LqNN {
                left: calc(50% - 210px); }
              .Progress_container__225Ra .Progress_content__1hoYH .Progress_columns__2Z2eA .Progress_right__1JbkU > span .Progress_videoPlaceholder__1Nx7y {
                width: 100%; } }

.Edit_container__2b9eQ {
  padding-top: 25px; }
  .Edit_container__2b9eQ .Edit_title__2cZH9 {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: normal;
    line-height: 28px;
    font-size: 16px;
    color: #E31E24; }
    .Edit_container__2b9eQ .Edit_title__2cZH9 img {
      margin-right: 15px; }
  .Edit_container__2b9eQ .Edit_description__aDXkZ {
    padding-top: 30px;
    line-height: 19px;
    font-size: 13px;
    color: #000000; }
  .Edit_container__2b9eQ .Edit_intervals__bailb .Edit_interval__1N0hN {
    padding-top: 30px;
    display: flex;
    align-items: center;
    line-height: 19px;
    font-size: 13px;
    color: #000000; }
    .Edit_container__2b9eQ .Edit_intervals__bailb .Edit_interval__1N0hN > * {
      margin-right: 10px; }
    .Edit_container__2b9eQ .Edit_intervals__bailb .Edit_interval__1N0hN > span {
      margin-top: 5px; }
    .Edit_container__2b9eQ .Edit_intervals__bailb .Edit_interval__1N0hN img {
      cursor: pointer;
      margin-left: 5px; }
    .Edit_container__2b9eQ .Edit_intervals__bailb .Edit_interval__1N0hN label {
      display: none; }
    .Edit_container__2b9eQ .Edit_intervals__bailb .Edit_interval__1N0hN input {
      width: 75px;
      height: 29px; }
  .Edit_container__2b9eQ .Edit_intervals__bailb .Edit_add__3bKZt {
    margin-top: 30px;
    cursor: pointer;
    line-height: 19px;
    font-size: 13px;
    color: #E31E24; }
  .Edit_container__2b9eQ .Edit_intervals__bailb .Edit_button__316eM {
    margin-top: 20px;
    display: block;
    width: 178px;
    height: 38px; }


.Process_container__2fdYL {
  margin-top: 20px !important;
  margin-bottom: -10px !important;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .Process_container__2fdYL .Process_stage__2qIwI {
    height: 32px; }
    .Process_container__2fdYL .Process_stage__2qIwI svg {
      width: 24px;
      margin-left: 24px; }
    .Process_container__2fdYL .Process_stage__2qIwI.Process_current__2B_2s {
      color: #E31E24; }
  .Process_container__2fdYL.Process_error__39jOZ {
    color: #E31E24 !important; }

.MyPage_container__2iajZ {
  margin: 140px 0 80px;
  display: flex;
  justify-content: center; }
  .MyPage_container__2iajZ .MyPage_content__1Y1is {
    width: 1000px; }
    .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t {
      display: flex;
      justify-content: space-between; }
      .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b {
        width: 450px; }
        .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b img {
          margin: 25px 0 30px; }
        .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b td {
          padding-bottom: 10px; }
        .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b td:first-of-type, .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b .MyPage_label__RsFVv {
          line-height: 19px;
          font-size: 13px;
          min-width: 180px;
          color: #000000; }
        .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b td:last-of-type, .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b .MyPage_value__1KR4M {
          font-weight: 600;
          line-height: 19px;
          font-size: 13px;
          color: #000000; }
        .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b .MyPage_label__RsFVv {
          margin: 30px 0 10px; }
        .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b button {
          margin-top: 30px; }
      .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_right__3Ss1p {
        width: 277px;
        height: 315.55px;
        margin: 30px 40px;
        background-image: url(/static/media/microphone.d146562d.svg);
        background-position: right top;
        background-repeat: no-repeat; }
  @media (max-width: 1200px) {
    .MyPage_container__2iajZ {
      margin: 0; }
      .MyPage_container__2iajZ .MyPage_content__1Y1is {
        width: 100%; }
        .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b {
          width: 100%; }
          .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b table {
            padding: 30px 30px 0; }
            .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b table tr {
              display: flex;
              flex-direction: column; }
              .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b table tr td:first-of-type {
                padding-bottom: 8px; }
              .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b table tr td:last-of-type {
                font-size: 14px;
                padding-bottom: 20px; }
          .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b .MyPage_label__RsFVv {
            margin-top: 0;
            margin-left: 30px; }
          .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b .MyPage_value__1KR4M {
            font-size: 14px;
            margin-left: 30px; }
          .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_left__3327b button {
            margin-left: 30px;
            margin-bottom: 45px;
            width: 200px; }
        .MyPage_container__2iajZ .MyPage_content__1Y1is .MyPage_columns__3vZ8t .MyPage_right__3Ss1p {
          display: none; } }

.EmailSent_container__2H-9R {
  background: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-size: 64px;
  line-height: 77px;
  color: #FFFFFF; }
  .EmailSent_container__2H-9R img {
    margin-bottom: 35px; }

