.container {
  position: relative;

  .icon {
    position: absolute;

    left: 14px;
    bottom: 16px;

    width: 56px;
    height: 56px;
  }

  .picture {
    max-width: 400px;
  }

  &.small {
    .picture {
      max-height: 190px;
    }
  }

  &.big {
    .picture {
      max-width: 700px;
    }
  }

  @media (max-width: 1400px) {
    &.small {
      .picture {
        max-height: 162px;
      }
    }
  }

  @media (max-width: 1200px) {
    .picture {
      max-width: unset !important;
      width: 100%;
      margin: 0 !important;
    }

    @media (max-width: 833px) {
      &.small {
        .picture {
          max-height: 235px;
        }
      }
    }
  }

  @media (min-width: 1201px) {
    &.hideForDesktop {
      display: none;
    }
  }
}