.container {
  margin: 0;

  font-family: 'PT Mono', monospace;
  line-height: normal;
  font-size: 72px;

  color: #000000;

  @media (max-width: 1200px) {
    background-color: black;
    padding: 20px 68px 20px 12px;

    color: #fff;

    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 28px;
  }
}