.button {
  background-color: transparent;
  border-radius: 90px;
  border: 1px solid #FFFFFF;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  height: 46px;
  padding: 0 24px;
  cursor: pointer;
  font-weight: bold;
  transition: all .3s ease-in-out;
  margin-right: 18px;

  &.primary {
    background-color: #E31E24;
    border: none;
  }
  &.cancel {
    background: rgba(255, 255, 255, 0.3);
    color: black;

    &:not(:hover) {
      border: 2px solid #474747;
    }
  }
  &.small {
    height: 38px;
  }
  &:hover, &.disabled {
    color: #E31E24;
    background-color: #ECECEC;
  }
  &.disabled {
    cursor: default;
  }
  &:focus {
    outline: none;
  }
}
