.container {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 5px;

    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    font-size: 14px;

    color: #000000;
  }

  .select {
    > div:first-of-type {
      height: 40px;

      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.3);
      box-sizing: border-box;
      border-radius: 1px;
    }

    * {
      font-style: normal;
      font-weight: normal;
      line-height: 19px;
      font-size: 13px;

      color: #000000;
    }

    &.disabled {
      > div:first-of-type {
        background: #F6F6F6;
      }
    }
  }
}