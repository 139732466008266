.container {
  padding-top: 25px;

  .title {
    display: flex;
    align-items: center;
    cursor: pointer;

    font-weight: normal;
    line-height: 28px;
    font-size: 16px;

    color: #E31E24;

    img {
      margin-right: 15px;
    }
  }

  .description {
    padding-top: 30px;

    line-height: 19px;
    font-size: 13px;

    color: #000000;
  }

  .intervals {
    .interval {
      padding-top: 30px;

      display: flex;
      align-items: center;

      line-height: 19px;
      font-size: 13px;

      color: #000000;

      >* {
        margin-right: 10px;
      }

      >span {
        margin-top: 5px;
      }

      img {
        cursor: pointer;
        margin-left: 5px;
      }

      label {
        display: none;
      }

      input {
        width: 75px;
        height: 29px;
      }
    }

    .add {
      margin-top: 30px;
      cursor: pointer;

      line-height: 19px;
      font-size: 13px;

      color: #E31E24;
    }

    .button {
      margin-top: 20px;
      display: block;

      width: 178px;
      height: 38px
    }
  }
}
