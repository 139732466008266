.invalid {
  position: relative;

  &:after {
    position: absolute;
    content: "";
    top:0;
    left:0;

    border: 1px solid #E31E24;
    width: 300px;
    height: 75px;

    pointer-events: none;
  }
}