.item {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    margin-bottom: 10px;
  }
}