.container {
  margin-top: 20px !important;
  margin-bottom: -10px !important;

  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .stage {
    height: 32px;

    svg {
      width: 24px;
      margin-left: 24px;
    }

    &.current {
      color: #E31E24;
    }
  }

  &.error {
    color: #E31E24 !important;
  }
}