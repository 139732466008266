@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700|PT+Mono&subset=cyrillic');

@font-face {
  font-family: 'Futura PT';
  font-style: normal;
  font-weight: 400;
  src: url('../src/Resources/Fonts/FuturaPT/FuturaPT-Medium.eot'); /* IE9 Compat Modes */
  src: local('Futura PT'), local('FuturaPT'),
  url('../src/Resources/Fonts/FuturaPT/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../src/Resources/Fonts/FuturaPT/FuturaPT-Medium.woff') format('woff'), /* Super Modern Browsers */
  url('../src/Resources/Fonts/FuturaPT/FuturaPT-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
}

* {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%;
}

.react-datepicker {
  font-size: 14px !important;
  width: 313px;
}

label[required]:after {
  content: " *";
  color: #E31E24;
}

body .video-react .video-react-big-play-button {
  top: calc(50% - 22.5px);
  left: calc(50% - 45px);
}
