.container {
  width: 708px;
  margin: 160px 180px 105px;

  img {
    width: 100%;
  }

  h1 {
    margin-top: 32px;
    margin-bottom: 12px;
  }

  .subtitle {
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    font-size: 14px;

    color: #474747;

    margin-right: 10px;
  }

  .count {
    line-height: 23px;
    font-size: 14px;
    color: #474747;
    margin-right: 10px;
    &:before {
      background-image: url("../../Events/Images/user.svg");
      background-repeat: no-repeat;
      display: inline-block;
      width: 12px;
      height: 15px;
      content: "";
      margin-right: 10px;
      background-position-y: 4px;
    }
  }

  h2 {
    margin: 30px 0 16px;

    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    font-size: 16px;

    color: #000000;

    span {
      font-style: normal;
      font-weight: normal;
      line-height: 28px;
      font-size: 16px;

      color: #000000;

      margin: 0 12px;
      &.red {
        margin: 0;
        color: #E31E24;
      }
    }
  }

  .description {
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    font-size: 16px;

    color: #000000;
  }

  @media (max-width: 1200px) {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;

    h1 {
      margin: 0;
      order: -1;
    }

    .title {
      margin-top: 30px;
      margin-left: 12px;
      font-size: 28px;
      margin-bottom: 0;
    }

    p {
      margin-left: 12px;
    }

    .subtitle {
      margin: 15px 12px 5px;
      line-height: 21px;
      font-size: 12px;
    }

    h2 {
      margin: 15px 12px 15px;
      line-height: 21px;
      font-size: 12px;

      span {
        line-height: 21px;
        font-size: 12px;

        margin: 0 8px;
        &.red {
          margin: 0;
        }
      }

      span:nth-last-child(2) {
        display: none;
      }
      span:last-of-type {
        display: block;
      }
    }

    .description {
      margin: 15px 12px 5px;
      font-weight: normal;
      line-height: 25px;
      font-size: 14px;
    }

    .location {
      margin: 15px 12px 30px;

      line-height: 21px;
      font-size: 12px;
    }
  }
}