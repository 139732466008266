.container {
  .subtitle {
    margin-top: 52px;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    line-height: 30px;
  }

  .row {
    margin-top: 30px;
  }

  .error {
    display: flex;
    align-items: center;

    color: #E31E24;
    margin-top: 15px;
    margin-bottom: 15px;
    height: 0;
    opacity: 0;
    transition: 0.3s;

    &.visible {
      opacity: 1;
      height: 40px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}