.container {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 5px;

    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    font-size: 14px;

    color: #000000;
  }

  textarea {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 1px;
    height: 120px;

    padding: 15px 10px;

    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    font-size: 13px;

    color: #000000;

    resize: vertical;

    &::placeholder {
      color: #9F9F9F;
    }

    &.invalid {
      border-color: #E31E24;

      outline: none;
    }
  }
}